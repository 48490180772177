<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('formatos.editar') + (formato ? ' - ' + formato.codigo + ' - ' + formato.nombre : '')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('formatos.formato') + (formato ? ' - ' + formato.codigo + ' - ' + formato.nombre : '')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('formatos.nuevoFormato')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="eye_fill"
          v-tooltip="'Volver a modo lectura'"
          :text="'Ver'"
          class="display-flex"
          @click="readonly=true"
        ></f7-button>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          :text="$t('common.guardar')"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <f7-button
          v-if="readonly"
          fill
          icon-f7="pencil"
          :text="$t('common.editar')"
          class="display-flex"
          @click="readonly=false"
        ></f7-button>
        <template slot="right">
          <div class="menu-item menu-item-dropdown bg-color-primary">
            <div class="menu-item-content"><i class="f7-icons">ellipsis_vertical</i></div>
            <div class="menu-dropdown menu-dropdown-right bg-color-primary">
              <div class="menu-dropdown-content bg-color-primary text-align-right">
                <div class="menu-dropdown-item menu-close pointer" v-if="!readonly" @click="importarComprobaciones()">{{$t('formatos.importarComprobaciones')}}</div>
                <div class="menu-dropdown-item menu-close pointer" v-if="formato && formato.id" @click="exportarComprobaciones()">{{$t('formatos.exportarComprobaciones')}}</div>
                <div class="menu-dropdown-divider"></div>
                <div class="menu-dropdown-item menu-close pointer" v-if="readonly" @click="readonly=false">{{$t('common.editar')}}</div>
                <div class="menu-dropdown-item menu-close pointer" v-if="readonly" @click="copy">{{$t('common.copy')}}</div>
                <div class="menu-dropdown-item menu-close pointer" v-if="!readonly" @click="$refs.popuppaste.open()">{{$t('common.paste')}}</div>
                <div class="menu-dropdown-item menu-close pointer" v-if="!readonly" @click="cancelarEdicion()">{{$t('common.cancelar')}}</div>
                <div class="menu-dropdown-divider"></div>
                <div class="menu-dropdown-item menu-close pointer" v-roles="['ADMIN', 'DTYCAL']" v-if="readonly && formato && formato.listadoCondicionesAuditoria && formato.listadoCondicionesAuditoria.length && formato.listadoCondicionesAuditoria.some(r=>r.regla)" @click="probarAuditoria = true">{{$t('Probar auditoria GPT')}}</div>
                <div class="menu-dropdown-item menu-close pointer" v-roles="['ADMIN', 'DTYCAL']" v-if="readonly && formato && formato.listadoCondicionesAuditoria && formato.listadoCondicionesAuditoria.length" @click="regenerarCondicionesGPT">{{$t('Regenerar condiciones auditoria GPT')}}</div>
              </div>
            </div>
          </div>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      :itemId="version ? null : id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Formato"
      class="full-height"
      classContent="full-height flex-direction-row"
      @loadItem="loadItem"
      :documentoAdapter="Documento"
      :beforeSave="beforeSaveFormato"
      :defaultData="{tabs: [], caracteristicas: [], comprobaciones: [], precargas:[], plantillasInforme:[ {provincias: [], condiciones: [], anexos: []}], nombreCategoriasDefectos: {}}"
    >
      <template v-slot="{rform}">
        <div class="page-content" style="flex:1;--f7-navbar-height: 0px;">
          <f7-subnavbar>
            <f7-segmented raised class="elevation-6">
              <f7-button
                fill
                tab-link="#tab-general"
                v-tooltip="'Esta pestaña nos muestra los datos generales del formato'"
                tab-link-active
                @click="tabActive = 'GENERAL'"
              >{{$t('formatos.datosGenerales')}}</f7-button>
              <f7-button fill tab-link="#tab-instalacion" @click="tabActive = 'INSTALACION'">Instalación</f7-button>
              <f7-button fill tab-link="#tab-tomadedatos" @click="tabActive = 'TOMADATOS'">Toma de datos</f7-button>
              <f7-button v-noroles="['ENAC', 'PRODUCCION']" fill tab-link="#tab-precargas" v-if="precargaHabilitada" @click="tabActive = 'PRECARGAS'">Precargas</f7-button>
              <f7-button fill tab-link="#tab-modelosinforme" @click="tabActive = 'MODELOSINFORME'">Modelos informe</f7-button>
            </f7-segmented>
          </f7-subnavbar>
          <f7-tabs class="full-height" ref="tabs">
            <f7-tab id="tab-general" class="page-content no-padding" tab-active>
              <Container group-name="grupo-general">
                <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
                  <f7-block-title>
                    <div class="label-group col-100">
                      {{$t('formatos.datosGenerales')}}
                      <!-- mostramos u ocultamos los datos generales del formato -->
                      <span style="float:right;">
                        <f7-link @click="showDatosGenerales = !showDatosGenerales" :icon-f7="showDatosGenerales?'chevron_up':'chevron_down'" v-tooltip="showDatosGenerales?'Ocultar':'Mostrar'"></f7-link>
                      </span>
                    </div>
                  </f7-block-title>
                  <div v-if="showDatosGenerales">
                    <f7-card-content>
                      <f7-row no-gap class="list list-form no-hairlines">
                        <r-input
                          class="col-33"
                          floating-label
                          :label="$t('formatos.codigo')"
                          :form="rform"
                          type="text"
                          name="codigo"
                          :readonly="typeOf(id) != 'undefined'"
                        ></r-input>
                        <r-input
                          class="col-33"
                          floating-label
                          :label="$t('formatos.nombre')"
                          :form="rform"
                          type="text"
                          name="nombre"
                        ></r-input>
                        <r-autocomplete
                          :dbAdapter="Materia"
                          class="col-33"
                          floating-label
                          :label="$t('formatos.materia')"
                          :form="rform"
                          name="materia.codigo"
                          labelProp="nombre"
                          idProp="codigo"
                          idFieldDetail="id"
                        ></r-autocomplete>
                        <r-input
                          class="col-33"
                          floating-label
                          :label="$t('formatos.fechaDesde')"
                          :form="rform"
                          type="datepicker"
                          name="fechaDesde"
                          :clearButton="true"
                          :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit'} }"
                        ></r-input>
                        <r-input
                          class="col-33"
                          floating-label
                          :label="$t('formatos.fechaHasta')"
                          :form="rform"
                          type="datepicker"
                          name="fechaHasta"
                          :clearButton="true"
                          :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit'} }"
                        ></r-input>
                        <r-input
                          class="col-33"
                          floating-label
                          :label="$t('formatos.periodicidadMeses')"
                          :form="rform"
                          name="periodicidadMeses"
                          type="number"
                        ></r-input>
                        <r-input
                          class="col-33"
                          floating-label
                          :label="$t('formatos.inspeccionable')"
                          :form="rform"
                          name="inspeccionable"
                          type="check"
                        ></r-input>
                        <r-input
                          class="col-33"
                          floating-label
                          :label="$t('formatos.tipomedida')"
                          :form="rform"
                          name="conTipoMedidaAAdoptar"
                          type="check"
                        ></r-input>
                        <r-autocomplete-m2m
                          class="col-33"
                          :dbAdapter="Provincia"
                          floating-label
                          :label="$t('formatos.provincias')"
                          :form="rform"
                          :name="'provincias'"
                          labelProp="nombre"
                          :shownFields="['codigo','nombre']"
                          idProp="codigo"
                          nameId="provinciasCodigo"
                          type="textarea"
                          :lineBreak="true"
                        ></r-autocomplete-m2m>
                        <r-input
                          v-noroles="['ENAC', 'PRODUCCION']"
                          class="col-25"
                          floating-label
                          :label="$t('formatos.comprobacionesEnPlantillas')"
                          :form="rform"
                          name="comprobacionesEnPlantillas"
                          type="check"
                        ></r-input>
                        <r-input
                          v-noroles="['ENAC', 'PRODUCCION']"
                          class="col-25 bgcolor-item"
                          floating-label
                          :label="$t('formatos.materiaEnItem')"
                          :form="rform"
                          name="materiaEnItem"
                          type="check"
                        ></r-input>
                        <r-input
                          v-noroles="['ENAC', 'PRODUCCION']"
                          class="col-25"
                          floating-label
                          :label="$t('formatos.tiempoInspMin')"
                          :form="rform"
                          name="tiempoInspMin"
                          type="number"
                        ></r-input>
                        <r-input
                          v-noroles="['ENAC', 'PRODUCCION']"
                          class="col-25"
                          floating-label
                          :label="$t('formatos.tiempoInspMax')"
                          :form="rform"
                          name="tiempoInspMax"
                          type="number"
                        ></r-input>
                      </f7-row>
                    </f7-card-content>
                    <f7-block-title><div class="label-group col-100">{{$t('formatos.textoCategoriasDefectos')}}</div></f7-block-title>
                    <f7-card-content>
                      <f7-row no-gap class="list list-form no-hairlines">
                        <r-input
                          class="col-33"
                          floating-label
                          :label="$t('formatos.defectoLeve')"
                          :form="rform"
                          name="nombreCategoriasDefectos.DL"
                        ></r-input>
                        <r-input
                          class="col-33"
                          floating-label
                          :label="$t('formatos.defectoGrave')"
                          :form="rform"
                          name="nombreCategoriasDefectos.DG"
                        ></r-input>
                        <r-input
                          class="col-33"
                          floating-label
                          :label="$t('formatos.defectoMuyGrave')"
                          :form="rform"
                          name="nombreCategoriasDefectos.DMG"
                        ></r-input>
                      </f7-row>
                    </f7-card-content>
                    <f7-block-title><div class="label-group col-100">Ayuda periodicidad</div></f7-block-title>
                    <f7-row no-gap class="list list-form no-hairlines">
                      <r-rich-text-input
                        :disabled="rform.readonly"
                        class="col-100"
                        :form="rform"
                        :name="'ayudaPeriodicidad'"
                      ></r-rich-text-input>
                    </f7-row>
                  </div>
                </f7-card>



                <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
                  <f7-block-title>
                    <div class="label-group col-100">
                      Condiciones para auditoría de inspecciones
                      <!-- Añadir Condición de auditoría -->
                      <f7-link v-if="!rform.readonly && rform.formData" @click="nuevaReglaAuditoria()" icon-f7="plus_square_fill" v-tooltip="'Crear nueva regla'"></f7-link>
                      <!-- Añadir múltiples reglas de una vez, usando un diálogo con un textbox -->
                      <f7-link v-if="!rform.readonly && rform.formData" @click="popupMultiReglaOpened = true;" icon-f7="plus_square_fill_on_square_fill" v-tooltip="'Crear múltiples reglas'"></f7-link>
                      <!-- Eliminar reglas -->
                      <f7-link v-if="!rform.readonly && rform.formData" @click="eliminaReglaAuditoriaAll()" icon-f7="minus_square_fill" color="red" v-tooltip="'Eliminar todas las reglas'"></f7-link>
                      <!-- Ver todas las reglas en modo texto -->
                      <f7-link v-if="rform.formData && rform.formData.listadoCondicionesAuditoria && rform.formData.listadoCondicionesAuditoria.length" @click="verReglasAuditoriaComoTexto()" icon-f7="textbox" v-tooltip="'Ver todas las reglas'"></f7-link>
                      <r-input style="display: inline-flex;border: 1px solid gray;border-radius: 5px;" label="Reglas de auditoría activas" :form="rform" type="check" :simple="true" name="activarReglasAuditorias"></r-input>

                      <!-- mostramos u ocultamos el listado de condiciones de auditoria -->
                      <span style="float:right;">
                        <f7-link @click="showCondicionesAuditoria = !showCondicionesAuditoria" :icon-f7="showCondicionesAuditoria?'chevron_up':'chevron_down'" v-tooltip="showCondicionesAuditoria?'Ocultar':'Mostrar'"></f7-link>
                      </span>
                    </div>
                  </f7-block-title>
                  <f7-popup ref="popupCondicionesAuditoriaTexto" v-if="popupCondicionesAuditoriaOpened" @popup:close="popupCondicionesAuditoriaOpened = false" :opened="true" style="--f7-popup-tablet-width: 75%;">
                    <f7-page>
                      <f7-navbar>
                        <f7-nav-left><f7-link v-if="rform.formData" @click="copiarAlPortapapeles(rform.formData.condicionesAuditoriaTexto.replaceAll('<br/>',''))" v-tooltip="'Copiar las reglas'">Copiar</f7-link></f7-nav-left>
                        <f7-nav-title>{{$t('Condiciones de auditoría en texto')}}</f7-nav-title>
                        <f7-nav-right><f7-link popup-close>Cerrar</f7-link></f7-nav-right>
                      </f7-navbar>
                      <div style="margin: 12px;" v-html="rform.formData.condicionesAuditoriaTexto"></div>
                    </f7-page>
                  </f7-popup>
                  <f7-popup ref="popupReglaInfo" v-if="popupReglaInfoOpened && rform.formData.reglaSeleccionada" @popup:close="popupReglaInfoOpened = false" :opened="true" style="--f7-popup-tablet-width: 75%;">
                    <detalle-regla :rform="rform" v-if="rform && rform.formData && rform.formData.reglaSeleccionada"></detalle-regla>
                  </f7-popup>
                  <f7-popup ref="popupMultiRegla" v-if="popupMultiReglaOpened" @popup:close="popupMultiReglaOpened = false" :opened="true" style="--f7-popup-tablet-width: 75%;">
                    <f7-page>
                      <f7-navbar>
                        <f7-nav-left><f7-link v-if="!rform.readonly && rform.formData" @click="nuevaReglaAuditoriaMultiple()" v-tooltip="'Crear múltiples reglas'">Crear</f7-link></f7-nav-left>
                        <f7-nav-title>Crear multiples reglas</f7-nav-title>
                        <f7-nav-right><f7-link popup-close>Cerrar</f7-link></f7-nav-right>
                      </f7-navbar>
                      <div class="textarea-reglas">
                        <f7-row no-gap class="list list-form row-100">
                          <f7-col width="100" medium="100">
                            <r-input
                              class="row-100"
                              style="z-index: 3000;"
                              floating-label
                              outline
                              label="Listado de reglas separadas por líneas"
                              :form="rform"
                              type="textarea"
                              name="nuevasReglas"
                              :clearButton="false"
                            ></r-input>
                          </f7-col>
                        </f7-row>
                      </div>
                    </f7-page>
                  </f7-popup>
                  <div v-if="showCondicionesAuditoria">
                    <f7-row no-gap>
                      <f7-col class="cabecera-condiciones" style="width: 5%;">
                        <f7-link v-if="!rform.readonly && rform.formData && rform.formData.listadoCondicionesAuditoria && rform.formData.listadoCondicionesAuditoria.length" @click="marcarDesmarcarCondicionesAuditoria()" :icon-f7="marcadoCondicionesAuditoria?'square':'checkmark_square_fill'" v-tooltip="(marcadoCondicionesAuditoria?'Marcar':'Desmarcar')+' todas las condiciones'"></f7-link>
                        Comprobada
                      </f7-col>
                      <f7-col class="cabecera-condiciones" style="width: 4%;" v-tooltip="Activa">
                        <f7-link v-if="!rform.readonly && rform.formData && rform.formData.listadoCondicionesAuditoria && rform.formData.listadoCondicionesAuditoria.length" @click="activarDesactivarTodasCondicionesAuditoria()" :icon-f7="activadoCondicionesAuditoria?'square':'checkmark_square_fill'" v-tooltip="(activadoCondicionesAuditoria?'Activar':'Desactivar')+' todas las condiciones'"></f7-link>
                        Activa
                      </f7-col>
                      <f7-col class="cabecera-condiciones" style="width: 88%;">Texto condición</f7-col>
                    </f7-row>
                    <f7-row
                      no-gap
                      :class="'list list-form no-hairlines reglasAuditoria linea-regla-' + rform.formData.listadoCondicionesAuditoria[indexRegla].tipoRegla"
                      v-for="(regla, indexRegla) in rform.formData.listadoCondicionesAuditoria"
                      :key="'listadoCondicionesAuditoria-'+indexRegla"
                      :style="'margin-left: 12px;' + (regla.activa?'border: 2px solid green;':'border: 2px solid orange;')"
                    >
                      <f7-col style="width: 2.5%;padding: 0 12px;" class="padding-horizontal-half display-flex">
                        <r-input
                          :form="rform"
                          :name="'listadoCondicionesAuditoria.'+indexRegla+'.comprobada'"
                          type="check"
                          :simple="true"
                          v-tooltip="'Marcaremos este check cuando la regla haya sido comprobada y ya no se modificará por la regeneración de reglas.'"
                        ></r-input>
                      </f7-col>
                      <f7-col style="width: 2.5%;padding: 0 12px;" class="padding-horizontal-half display-flex">
                        <f7-link v-if="regla.regla && regla.regla.regla" @click="detalleReglaAuditoria(indexRegla)" :color="regla.regla.error?'red':''" icon-f7="info_circle_fill" v-tooltip="'Ver detalles: '+regla.regla.regla" ></f7-link>
                        <f7-link v-if="!readonly && (!regla.regla || !regla.regla.regla)" @click="regla.regla={valoresAfectados:[]};detalleReglaAuditoria(indexRegla)" icon-f7="plus_circle" v-tooltip="'Crear regla manualmente'" ></f7-link>
                        <div class="numero-regla" v-roles="['ADMIN']">
                        </div>
                      </f7-col>
                      <f7-col style="width: 2%;padding: 0 12px;" class="padding-horizontal-half display-flex">
                        <div class="small-links-container">
                          <f7-link v-if="!readonly" class="link-arriba" icon-f7="cloud_upload_fill" :color="regla.evalOnSync?'green':'gray'" @click="regla.evalOnSync = !!!regla.evalOnSync" v-tooltip="'Ejecutar en la app'" ></f7-link>
                          <f7-link v-else class="link-arriba" icon-f7="cloud_upload_fill" :color="regla.evalOnSync?'green':'gray'" v-tooltip="'Ejecutar al sincronizar'" ></f7-link>
                          <f7-link v-if="!readonly" class="link-abajo" icon-f7="flag_fill" :color="regla.evalOnFinish?'green':'gray'"  @click="regla.evalOnFinish = !!!regla.evalOnFinish" v-tooltip="'Ejecutar antes de firma'" ></f7-link>
                          <f7-link v-else class="link-abajo" icon-f7="flag_fill" :color="regla.evalOnFinish?'green':'gray'"  v-tooltip="'Ejecutar al finalizar'" ></f7-link>
                        </div>
                        <r-input
                          :form="rform"
                          :name="'listadoCondicionesAuditoria.'+indexRegla+'.activa'"
                          type="check"
                          v-tooltip="'la regla está ' + (regla.activa?'activa':'inactiva') + ' y se evaluará en la auditoría.'"
                          :simple="true"
                        ></r-input>
                      </f7-col>
                      <f7-col style="width: 2%;padding: 0 12px;" class="padding-horizontal-half">
                        <div class="numero-regla">
                          <span style="display: inline-flex;">
                            {{ regla.numero }}
                          </span>
                        </div>
                      </f7-col>
                      <f7-col style="width: 88%;padding: 0 12px;" class="padding-horizontal-half">
                        <r-input
                          class="col-100"
                          :class="'regla-' + rform.formData.listadoCondicionesAuditoria[indexRegla].tipoRegla"
                          :form="rform"
                          :name="'listadoCondicionesAuditoria.'+indexRegla+'.textoCondicion'"
                          type="text"
                          :readonly="rform.readonly || regla.comprobada"
                          :overrideReadonly="true"
                          :clearButton="true"
                        ></r-input>
                      </f7-col>
                      <f7-col style="width: 2.3%;padding: 0 12px;" class="padding-horizontal-half">
                        <f7-link v-if="!rform.readonly" @click="eliminaReglaAuditoria(indexRegla)" icon-f7="minus_square_fill" color="red" v-tooltip="'Eliminar regla'"></f7-link>
                        <f7-link v-if="!rform.readonly" @click="duplicarReglaAuditoria(indexRegla)" icon-f7="square_fill_line_vertical_square_fill" color="green" v-tooltip="'Duplicar regla'"></f7-link>
                      </f7-col>
                    </f7-row>
                  </div>
                </f7-card>
              </Container>
            </f7-tab>
            <f7-tab id="tab-instalacion" ref="tabInstalacion" class="page-content no-padding">
              <Container v-if="tabActive == 'INSTALACION'"
                class="margin-top smooth-dnd-container"
                :class="{'container-grupo': !rform.formData.caracteristicas || !rform.formData.caracteristicas.length}"
                group-name="grupo-caracteristicas"
                @drop="onDrop(rform.formData.caracteristicas, $event)"
                :get-child-payload="getChildPayloadGrupoCaracteristicas(rform.formData.caracteristicas)"
                lock-axis="y"
                drag-handle-selector=".column-drag-handle"
              >
                <Draggable
                  v-for="(caracteristica,ic) in rform.formData.caracteristicas"
                  :key="'caracteristica-'+'-'+ic+'-'+caracteristica.nombre"
                >
                  <caracteristica
                    :onDrop="onDrop"
                    :getChildPayloadCaracteristicas="getChildPayloadCaracteristicas"
                    :tipo="caracteristica.tipo"
                    :caracteristica="caracteristica"
                    :form="rform"
                    :label="caracteristica.tipo == 'NOTA' ? 'Nota para el inspector' : caracteristica.label"
                    :name="'caracteristicas.'+ic"
                    :placeholder="caracteristica.tipo == 'NOTA' ? 'Escriba nota que aparecerá al inspector' : 'Valor por defecto'"
                    :editable="true"
                    :allCaracteristicas="rform.formData.caracteristicas"
                    @eliminar="rform.formData.caracteristicas.splice(ic,1);"
                    :key="'caracteristica-'+'-'+ic+'-'+caracteristica.nombre"
                  ></caracteristica>
                </Draggable>
              </Container>
            </f7-tab>
            <f7-tab id="tab-tomadedatos" ref="tabTomaDatos" class="page-content no-padding">
              <f7-tabs class="full-height" v-if="tabActive == 'TOMADATOS'">
                <f7-toolbar tabbar>
                  <f7-link tab-link="#tab-comprobaciones" tab-link-active @click="subTabTomaDatosActive = 'COMPROBACION'">Comprobaciones</f7-link>
                  <f7-link
                    tab-link="#tab-defectos"
                     @click="subTabTomaDatosActive = 'DEFECTOS'"
                  >Defectos</f7-link>
                  <f7-link tab-link="#tab-subformatos" v-tooltip="$t('formatos.subformatos')"  @click="subTabTomaDatosActive = 'SUBFORMATOS'">{{$t('formatos.subformatos')}}</f7-link>
                </f7-toolbar>
                <f7-tab ref="tabComprobaciones" id="tab-comprobaciones" tab-active class="page-content no-padding">
                  <f7-list accordion-list>
                    <Container
                      group-name="grupo-comprobaciones"
                      @drop="onDrop(rform.formData.comprobaciones, $event)"
                      drag-handle-selector=".column-drag-handle"
                      :get-child-payload="getChildPayloadGrupoComprobaciones(rform.formData.comprobaciones)"
                      :class="{'container-grupo': !rform.formData.comprobaciones || !rform.formData.comprobaciones.length}"
                      class="smooth-dnd-container"
                      lock-axis="y"
                    >
                      <r-input
                        floating-label
                        :label="'Editar desde intranet'"
                        :form="rform"
                        name="editarEnIntranet"
                        type="check"
                        :simple="true"
                      ></r-input>
                      <Draggable
                        v-for="(comprobacion,ic) in getComprobacionesSettingGroup(rform.formData.comprobaciones)"
                        :key="'comprobacion-' + ic"
                      >
                        <comprobacion
                          :comprobacion="comprobacion"
                          :name="'comprobaciones.' + ic"
                          :form="rform"
                          :resultadoDefecto="comprobacion.valorInicial"
                          :formato="true"
                          :getChildPayloadComprobaciones="getChildPayloadComprobaciones"
                          @delete="deleteComprobacion(rform.formData.comprobaciones, ic, $event)"
                        ></comprobacion>
                      </Draggable>
                    </Container>
                  </f7-list>
                </f7-tab>
                <f7-tab id="tab-defectos" class="page-content no-padding">
                  <template v-if="subTabTomaDatosActive == 'DEFECTOS'">
                    <div no-gap 
                      v-for="(compro, ic) in getComprobacionesDefectos(rform.formData.comprobaciones)"
                      :key="'compro-defecto-'+ic"
                      style="margin-top: 10px;"
                    >
                      <div>
                        {{ compro.codigo }} - {{ compro.nombre }}
                        <f7-link class="margin" icon-f7="plus_square_fill" v-if="!rform.readonly && rform.formData" @click="compro.defectos.push({codigo: compro.codigo + '.' + (compro.defectos.length + 1), nombre: '', categoria: 'DL'})"></f7-link>
                      </div>
                      <f7-list class="list-form" style="margin:0;">
                        <ul>
                          <Container 
                            v-if="rform && rform.formData" 
                            @drop="onDropDefectos(compro, $event)"
                            drag-handle-selector=".column-drag-handle"
                            lock-axis="y"
                          >
                            <Draggable
                              v-for="(defecto, idd) in compro.defectos"
                              :key="'compro-defecto-'+ic+'-def-'+idd"
                            >
                              <f7-row no-gap>
                                <f7-col width="5" style="padding:0;">
                                  <f7-list-input :readonly="true" label="Código" floating-label type="text" :value="defecto.codigo">
                                  </f7-list-input>
                                </f7-col>
                                <f7-col width="10" style="padding:0;">
                                  <f7-list-input :readonly="rform.readonly" label="Código visual" outline floating-label type="text" :value="defecto.codigoVisual" @change="defecto.codigoVisual =$event.target.value">
                                  </f7-list-input>
                                </f7-col>
                                <f7-col width="50" style="padding:0;">
                                  <f7-list-input :readonly="rform.readonly" label="Nombre" outline floating-label type="text" :value="defecto.nombre" @change="defecto.nombre=$event.target.value">
                                  </f7-list-input>
                                </f7-col>
                                <f7-col width="10" style="padding:0;">
                                  <f7-list-input :readonly="rform.readonly" label="Categoría" outline floating-label type="select" :value="defecto.categoria" @input="defecto.categoria=$event.target.value">
                                    <option value="DL">DL</option>
                                    <option value="DG">DG</option>
                                    <option value="DMG">DMG</option>
                                  </f7-list-input>
                                </f7-col>
                                <f7-col width="10" style="padding:0;">
                                  <f7-list-input :readonly="rform.readonly" label="Plazo Corr. (días)" outline floating-label type="number" :value="defecto.plazoCorreccion" @change="defecto.plazoCorreccion=$event.target.value">
                                  </f7-list-input>
                                </f7-col>
                                <f7-col class="padding" style="margin:0;width: 20px;">
                                  <f7-link
                                    @click="$delete(compro.defectos,idd); recalculoCodigosDefectos(compro);"
                                    style="padding:0;margin:0;width: 0;"
                                    icon-f7="trash"
                                    color="red"
                                    v-if="!rform.readonly"
                                  ></f7-link>
                                </f7-col>
                                <f7-col class="padding" style="margin:0 10px 0 0;width: 20px;">
                                  <span
                                    v-if="!readonly"
                                    class="column-drag-handle cursor-move"
                                    v-tooltip="$t('common.move')"
                                  >&uarr;&darr;</span>
                                </f7-col>
                                <hr width="100%"/>
                              </f7-row>
                            </Draggable>
                          </Container>
                        </ul>
                      </f7-list>
                    </div>
                  </template>
                </f7-tab>

                <f7-tab id="tab-subformatos" class="page-content no-padding">
                  <f7-block-title>
                    {{$t('formatos.subformatos')}}
                    <f7-link v-if="!rform.readonly && rform.formData" @click="rform.formData.tabs.push({})">{{$t('formatos.add')}}</f7-link>
                  </f7-block-title>
                  <f7-card 
                    class="elevation-3 padding-vertical-half padding-right"
                    v-for="(tab, ic) in rform.formData.tabs"
                    :key="'tabs-subformato-'+ic"
                    :style="'z-index: ' + (rform.formData.tabs.length - ic*10)"
                  > 
                    <f7-card-content>
                      <f7-list class="list-form">
                        <ul>
                          <f7-row no-gap>
                            <r-input
                              class="col-25"
                              floating-label
                              :label="'Nombre Pestaña'"
                              :form="rform"
                              :name="'tabs.'+ic+'.nombre'"
                            ></r-input>
                            <r-autocomplete
                              class="col-25"
                              :dbAdapter="Formato"
                              label="Formato"
                              :form="rform"  
                              :name="'tabs.'+ic+'.formato'"
                              labelProp="nombre"
                              prefixProp="codigo"
                              :shownFields="['codigo', 'fechaDesde', 'fechaHasta', 'comprobaciones', 'caracteristicas']"
                            >
                            </r-autocomplete>
                            <r-input
                              class="col-25"
                              floating-label
                              :label="'Tipo'"
                              :form="rform"
                              type="select"
                              :name="'tabs.'+ic+'.tipo'"
                            >
                              <option value="GENERAL">General</option>
                              <option value="CUADROS">Cuadros</option>
                              <option value="TRANSFORMADORES">Transformadores</option>
                            </r-input>
                            <r-input
                              class="col-25"
                              floating-label
                              :label="'Versión'"
                              :form="rform"
                              type="number"
                              :name="'tabs.' + ic + '.version'"
                            ></r-input>
                          </f7-row>
                        </ul>
                      </f7-list>
                    </f7-card-content>
                    <f7-link
                      v-if="!rform.readonly && rform.formData"
                      @click="$delete(rform.formData.tabs, ic);"
                      class="margin-half"
                      style="position:absolute;right:0;top:0;"
                      icon-f7="trash"
                    ></f7-link>
                  </f7-card>
                  <f7-block-title>
                    {{$t('formatos.subformatos')}}
                    <f7-link v-if="!rform.readonly && rform.formData" @click="rform.formData.tabs.push({})">{{$t('formatos.add')}}</f7-link>
                  </f7-block-title>
                </f7-tab>
              </f7-tabs>
            </f7-tab>
            
            <f7-tab v-noroles="['ENAC', 'PRODUCCION']" id="tab-precargas" class="page-content no-padding">
              <!-- Precargas -->
            	<f7-card class="padding-vertical-half padding-right" style="z-index: 3000" v-if="tabActive == 'PRECARGAS'">
                <f7-block-title><div class="label-group col-100">{{$t('formatos.precargas')}}</div></f7-block-title>
                <f7-block-title style="z-index: 30"
                  class="accordion-opposite"
                >
                  <Container 
                    v-if="$refs.rform && $refs.rform.formData" 
                    @drop="onDrop(rform.formData.precargas, $event)"
                    drag-handle-selector=".column-drag-handle"
                    lock-axis="y"
                  >
                    <Draggable
                      v-for="(precarga, ip) in rform.formData.precargas"
                      :key="'precarga-'+ip"
                    >
                      <f7-list v-if="rform.formData.precargas && rform.formData.precargas.length" accordion-list>
                        <f7-list-item accordion-item>
                          <span
                            v-if="!readonly"
                            slot="content-start"
                            class="column-drag-handle cursor-move margin-right"
                            v-tooltip="$t('common.move')"
                            >&uarr;&darr;</span
                          >
                          <input
                            slot="before-title"
                            class="nombre-precarga"
                            :value="precarga.nombre"
                            @change="precarga.nombre = $event.target.value"
                            :placeholder="$t('formatos.nombre_precarga')"
                          />
                          <f7-link
                            v-if="!readonly"
                            style="margin-top:5px; position:absolute; right: 55px; top:3px;"
                            icon-f7="plus_square_fill_on_square_fill"
                            @click="duplicarPrecarga(ip)"
                            v-tooltip="'Duplicar precarga'"
                          ></f7-link>
                          <!-- <span 
                            v-if="!readonly"
                            style="margin-top:2px; position:absolute; right: 35px; top:3px;"
                            class="column-drag-handle margin-left cursor-move col-5"
                            v-tooltip="$t('common.move')"
                          >&uarr;&darr;</span> -->
                          <f7-link
                            v-if="!readonly"
                            style="margin-top:5px; position:absolute; right: 5px; top:3px;"
                            icon-f7="minus_circle_fill"
                            color="red"
                            @click="eliminarPrecarga(ip)"
                          ></f7-link>
                          <f7-accordion-content>
                            <r-autocomplete-m2m
                              :dbAdapter="Provincia"
                              floating-label
                              :label="$t('comunidades.provincias')"
                              :form="rform"
                              :name="'precargas.'+ ip +'.provincias'"
                              labelProp="nombre"
                              idProp="codigo"
                              :lineBreak="false"
                            ></r-autocomplete-m2m>
                            <f7-block-title>
                              <div class="label-group float-left">
                                {{$t('formatos.condiciones')}}
                              </div>
                            </f7-block-title>
                            <div class="item-content" v-if="!precarga.condiciones || !precarga.condiciones.length">
                              <div class="item-inner">
                                <div class="item-cabecera">
                                  <f7-block>{{$t('formatos.noCondicionesPlantilla')}}</f7-block>
                                </div>
                              </div>
                            </div>
                            <f7-list v-if="rform.formData.precargas && rform.formData.precargas.length"
                              style="z-index: 5">
                              <f7-list-item
                                class="col-100"
                                v-for="(condicion, ic) in precarga.condiciones"
                                :key="'condiciones-'+ip+'-'+ic"
                                :style="'z-index: '+(((rform.formData.precargas.length - ip)*precarga.condiciones.length - ic)*10)"
                              >
                                <f7-row no-gap class="list list-form no-hairlines">
                                  <r-autocomplete
                                    class="col-33"
                                    label="Característica"
                                    :form="rform"  
                                    :name="'precargas.'+ip+'.condiciones.'+ic+'.nombre'"
                                    labelProp="nombre"
                                    idProp="nombre"
                                    :dbItems="getCaracteristicas(rform.formData.caracteristicas)"
                                  >
                                  </r-autocomplete>
                                  <r-input
                                    class="col-33"
                                    floating-label
                                    :label="'Operación'"
                                    :form="rform"
                                    type="select"
                                    :name="'precargas.'+ip+'.condiciones.'+ic+'.operacion'"
                                  >
                                    <option value="IGUAL">==</option>
                                    <option value="DIFERENTE">!=</option>
                                    <option value="MAYOR">&gt;</option>
                                    <option value="MENOR">&lt;</option>
                                  </r-input>
                                  <r-input
                                    class="col-33"
                                    floating-label
                                    :label="'Valor'"
                                    :form="rform"
                                    :name="'precargas.'+ip+'.condiciones.'+ic+'.valor'"
                                  ></r-input>
                                  <f7-link
                                    style="margin-top:5px; position:absolute; right: -20px; top: 16px;"
                                    icon-f7="minus_circle_fill"
                                    color="red"
                                    @click="eliminarCondicionPrecarga(ip, ic)"
                                  ></f7-link>
                                </f7-row>
                              </f7-list-item>
                            </f7-list>
                            <f7-link
                              class="margin-left margin-bottom"
                              v-if="!rform.readonly && rform.formData"
                              @click="nuevaCondicionPrecarga(precarga)"
                              style="z-index: 4"
                            >{{$t('formatos.anadirCondicion')}}</f7-link>
                            <f7-block-title><div class="label-group col-100">{{$t('formatos.valores')}}</div></f7-block-title>
                            <f7-list v-if="rform.formData.precargas && rform.formData.precargas.length" style="z-index: 3">
                              <f7-list-item
                                class="row-valores"
                                v-for="(comprobacion, iv) in precarga.valores"
                                :key="'condiciones-'+ip+'-'+iv"
                                :style="'z-index: '+(((rform.formData.precargas.length - ip)*precarga.valores.length - iv)*10)"
                              >
                                <r-autocomplete
                                  style="width: 100%;"
                                  :placeholder="$t('formatos.comprobacion')"
                                  :form="rform"  
                                  :name="'precargas.'+ip+'.valores.'+iv + '.codigo'"
                                  labelProp="codigoNombre"
                                  idProp="codigo"
                                  :dbItems="getComprobaciones(rform.formData.comprobaciones)"
                                ></r-autocomplete>
                                <div class="comprobacion-radios align-items-center display-flex" style="width: 140px;">
                                  <label
                                    v-for="rv in [
                                      { valor: 'F', color: 'green' },
                                      { valor: 'D', color: 'orange' },
                                      { valor: 'NA', color: 'gray' },
                                    ]"
                                    :key="'rvc-' + comprobacion.codigo + '-' + rv.valor"
                                    class="radio label-radio"
                                    :class="{ disabled: readonly }"
                                  >
                                    <f7-button
                                      :fill="getValorComprobacion(ip, iv) == rv.valor"
                                      raised
                                      :color="rv.color"
                                      @click="setValorComprobacion(ip, iv, rv.valor)"
                                      >{{ rv.valor }}</f7-button
                                    >
                                  </label> 
                                  <label
                                    :key="'rvc-lock'"
                                    class="radio label-radio margin-left"
                                    :class="{ disabled: readonly }"
                                  >
                                    <f7-button
                                      raised
                                      :fill="getBloqueado(ip, iv)"
                                      color="gray"
                                      @click="toggleBloqueado(ip, iv)"
                                      :icon-f7="getBloqueado(ip, iv) ? 'lock_fill' : 'lock_open_fill'"
                                      v-tooltip="getBloqueado(ip, iv) ? $t('common.bloqueado') : $t('common.desbloqueado')"
                                      ></f7-button
                                    >
                                  </label>
                                </div>
                              </f7-list-item>
                            </f7-list>
                            <f7-link
                              class="margin-left margin-bottom"
                              v-if="!rform.readonly && rform.formData"
                              @click="nuevoValorPrecarga(ip)"
                              style="z-index: 2; padding-bottom: 10em"
                            >{{$t('formatos.anadirValor')}}</f7-link>
                          </f7-accordion-content>
                        </f7-list-item>
                      </f7-list>
                    </Draggable>
                  </Container>
                </f7-block-title>
                <f7-block-title>
                  <div v-if="!rform.formData.precargas || !rform.formData.precargas.length">{{$t('formatos.mensaje_nueva_plantilla')}}</div>
                  <f7-link
                    class="margin-top"
                    v-if="!rform.readonly && rform.formData" 
                    @click="nuevaPrecarga()"
                  >
                    {{$t('formatos.nueva_precarga')}}
                  </f7-link>
                </f7-block-title>
              </f7-card>
            </f7-tab>
            
            <f7-tab id="tab-modelosinforme" class="page-content no-padding">
     	      <!-- Plantillas de informe --> 
              <f7-card class="padding-vertical-half padding-right" v-if="tabActive == 'MODELOSINFORME'" style="z-index: 3000">
                <f7-block-title><div class="label-group col-100">{{$t('formatos.modelosPlantilla')}}</div></f7-block-title>
                <f7-block-title  v-if="$refs.rform && $refs.rform.formData" style="z-index: 30"
                  class="accordion-opposite">
                  <Container 
                    v-if="$refs.rform && $refs.rform.formData" 
                    @drop="onDrop(rform.formData.plantillasInforme, $event)"
                    drag-handle-selector=".column-drag-handle"
                    lock-axis="y"
                  >
                    <Draggable
                      v-for="(plantilla, ip) in rform.formData.plantillasInforme"
                      :key="'plantilla-'+ip"
                    >
                      <f7-list v-if="rform.formData.plantillasInforme && rform.formData.plantillasInforme.length" accordion-list>
                        <f7-list-item accordion-item>
                          <span
                            v-if="!readonly"
                            slot="content-start"
                            class="column-drag-handle cursor-move margin-right"
                            v-tooltip="$t('common.move')"
                            >&uarr;&darr;</span
                          >
                          <input
                            slot="before-title"
                            class="nombre-plantilla"
                            :value="plantilla.nombre"
                            @change="plantilla.nombre = $event.target.value"
                            :placeholder="$t('formatos.nombre_plantilla')"
                          />
                          <f7-link
                            style="margin-top:5px; position:absolute; right: 30px; top:3px;"
                            icon-f7="doc_on_doc_fill"
                            color="primary"
                            @click="duplicarPlantilla(plantilla, ip)"
                            v-tooltip="'Duplicar'"
                          ></f7-link>
                          <f7-link
                            style="margin-top:5px; position:absolute; right: 5px; top:3px;"
                            icon-f7="minus_circle_fill"
                            color="red"
                            @click="eliminarPlantilla(ip)"
                            v-tooltip="'Eliminar'"
                          ></f7-link>
                          <f7-accordion-content>
                            <r-autocomplete-m2m
                              :dbAdapter="Provincia"
                              floating-label
                              :label="$t('comunidades.provincias')"
                              :form="rform"
                              :name="'plantillasInforme.'+ ip +'.provincias'"
                              labelProp="nombre"
                              idProp="codigo"
                              idFieldDetail="id"
                              :lineBreak="false"
                            ></r-autocomplete-m2m>
                            <f7-block-title>
                              <div class="label-group float-left">
                                {{$t('formatos.condiciones')}}
                              </div>
                            </f7-block-title>
                            <r-input
                              class="col-25"
                              :label="$t('formatos.tipoInspeccion')"
                              :form="rform"
                              type="select"
                              :clear-button="false"
                              :name="'plantillasInforme.'+ip+'.segundaInspeccion'"
                            >
                              <option value="">{{$t('common.any')}}</option>
                              <option value="UNO">{{$t('formatos.tipoInspeccionPrimera')}}</option>
                              <option value="DOS">{{$t('formatos.tipoInspeccionSegundas')}}</option>
                            </r-input>
                            <r-input
                              class="col-25"
                              :label="$t('formatos.resultadoInspeccion')"
                              :form="rform"
                              type="select"
                              :clear-button="false"
                              :name="'plantillasInforme.'+ip+'.inspeccionFavorable'"
                            >
                              <option value="">{{$t('common.any')}}</option>
                              <option value="FAVORABLE">{{$t('formatos.resultadoInspeccionFavorable')}}</option>
                              <option value="DESFAVORABLE">{{$t('formatos.resultadoInspeccionNoFavorable')}}</option>
                            </r-input>
                            <r-input
                              class="col-25"
                              :label="$t('formatos.calificacion')"
                              :form="rform"
                              type="select"
                              :clear-button="false"
                              :name="'plantillasInforme.'+ip+'.calificacion'"
                            >
                              <option value="">{{$t('common.any')}}</option>
                              <option value="FAVORABLE">Favorable</option>
                              <option value="FAVORABLE_CON_DEFECTOS">Favorable con defectos</option>
                              <option value="FAVORABLE_CON_REPARO">Favorable con reparo (reiteración defecto leve inspección anterior)</option>
                              <option value="DESFAVORABLE">Condicionada</option>
                              <option value="NEGATIVO">Negativa</option>
                            </r-input>
                            <r-input
                              floating-label
                              outline
                              label="Tiene defectos leves"
                              :form="rform"
                              :simple="true"
                              type="check"
                              :name="'plantillasInforme.'+ip+'.tieneDefectosLeves'"
                            ></r-input>
                            <r-input
                              floating-label
                              outline
                              label="Tiene defectos graves"
                              :form="rform"
                              :simple="true"
                              type="check"
                              :name="'plantillasInforme.'+ip+'.tieneDefectosGraves'"
                            ></r-input>
                            <r-input
                              floating-label
                              outline
                              label="Tiene defectos muy graves"
                              :form="rform"
                              :simple="true"
                              type="check"
                              :name="'plantillasInforme.'+ip+'.tieneDefectosMuyGraves'"
                            ></r-input>
                            <r-input id="selectVersion"
                              class="col-25"
                              floating-label
                              :label="$t('formatos.version')"
                              :form="rform"
                              type="select"
                              :name="'plantillasInforme.'+ip+'.version'"
                              :clearButton="false"
                            >
                              <option value="S">{{$t('formatos.versionSi')}}</option>
                              <option value="N">{{$t('formatos.versionNo')}}</option>
                            </r-input>
                            <div class="item-content" v-if="!plantilla.condiciones || !plantilla.condiciones.length">
                              <div class="item-inner">
                                <div class="item-cabecera">
                                  <f7-block>{{$t('formatos.noCondicionesPlantilla')}}</f7-block>
                                </div>
                              </div>
                            </div>
                            <f7-list v-if="rform.formData.plantillasInforme && rform.formData.plantillasInforme.length"
                              style="z-index: 3">
                              <f7-list-item
                                class="col-100"
                                v-for="(condicion, ic) in plantilla.condiciones"
                                :key="'condiciones-'+ip+'-'+ic"
                                :style="'z-index: '+(((rform.formData.plantillasInforme.length - ip)*plantilla.condiciones.length - ic)*10)"
                              >
                                <f7-row no-gap class="list list-form no-hairlines">
                                  <r-autocomplete
                                    class="col-33"
                                    label="Característica"
                                    :form="rform"  
                                    :name="'plantillasInforme.'+ip+'.condiciones.'+ic+'.nombre'"
                                    labelProp="nombre"
                                    idProp="nombre"
                                    :dbItems="getCaracteristicas(rform.formData.caracteristicas)"
                                  >
                                  </r-autocomplete>
                                  <r-input
                                    class="col-33"
                                    floating-label
                                    :label="'Operación'"
                                    :form="rform"
                                    type="select"
                                    :name="'plantillasInforme.'+ip+'.condiciones.'+ic+'.operacion'"
                                  >
                                    <option value="IGUAL">==</option>
                                    <option value="DIFERENTE">!=</option>
                                    <option value="MAYOR">&gt;</option>
                                    <option value="MENOR">&lt;</option>
                                  </r-input>
                                  <r-input
                                    class="col-33"
                                    floating-label
                                    :label="'Valor'"
                                    :form="rform"
                                    :name="'plantillasInforme.'+ip+'.condiciones.'+ic+'.valor'"
                                  ></r-input>
                                  <f7-link
                                    style="margin-top:5px; position:absolute; right: -20px; top: 16px;"
                                    icon-f7="minus_circle_fill"
                                    color="red"
                                    @click="eliminarCondicionPlantilla(ip, ic)"
                                  ></f7-link>
                                </f7-row>
                              </f7-list-item>
                            </f7-list>
                            <f7-link
                              class="margin-left margin-bottom"
                              v-if="!rform.readonly && rform.formData"
                              @click="plantilla.condiciones.push({})"
                              style="z-index: 2"
                            >{{$t('formatos.anadirCondicion')}}</f7-link>
                            <f7-row class="margin-top"
                              style="z-index: 1">
                              <div class="col-33">
                                <r-autocomplete
                                  ref="documentosPlantilla"
                                  class="col-25"
                                  floating-label
                                  :label="$t('formatos.certificado')"
                                  :form="rform"
                                  :dbItems="documentosPlantilla"
                                  :name="'plantillasInforme.'+ ip +'.certificadoPath'"
                                  labelProp="path"
                                  idProp="path"
                                ></r-autocomplete>
                              </div>
                              <div class="col-33">
                                <r-autocomplete
                                  ref="documentosPlantilla"
                                  class="col-25"
                                  floating-label
                                  :label="$t('formatos.tomaDatos')"
                                  :form="rform"
                                  :dbItems="documentosPlantilla"
                                  :name="'plantillasInforme.'+ ip +'.datosPath'"
                                  labelProp="path"
                                  idProp="path"
                                ></r-autocomplete>
                              </div>
                              <div class="col-33"></div>
                            </f7-row>
                            <f7-block-title>
                              <div class="label-group float-left">
                                {{$t('formatos.anexos')}}
                              </div>
                            </f7-block-title>
                            <div class="item-content" v-if="!plantilla.anexos || !plantilla.anexos.length">
                              <div class="item-inner">
                                <div class="item-cabecera">
                                  <f7-block>{{$t('formatos.noAnexosPlantilla')}}</f7-block>
                                </div>
                              </div>
                            </div>
                            <f7-list v-if="rform.formData.plantillasInforme && rform.formData.plantillasInforme.length"
                              style="z-index: 2">
                              <f7-list-item
                                class="item-anexo"
                                v-for="(anexo, ic) in plantilla.anexos"
                                :key="'anexo-'+ip+'-'+ic"
                                :style="'z-index: '+(((rform.formData.plantillasInforme.length - ip)*plantilla.anexos.length - ic)*10) + '; display: block;'"
                              >
                                <f7-row no-gap class="list list-form no-hairlines">
                                  <r-autocomplete
                                    ref="documentosPlantilla"
                                    class="col-30"
                                    floating-label
                                    :label="$t('formatos.anexo')"
                                    :form="rform"
                                    :dbItems="documentosPlantilla"
                                    :name="'plantillasInforme.'+ip+'.anexos.'+ic+'.anexoPath'"
                                    labelProp="path"
                                    idProp="path"
                                  ></r-autocomplete>
                                  <r-input
                                    class="col-10"
                                    floating-label
                                    :label="$t('formatos.condicionado')"
                                    :form="rform"
                                    :name="'plantillasInforme.'+ip+'.anexos.'+ic+'.condicionado'"
                                    type="check"
                                  ></r-input>
                                  <r-autocomplete
                                    v-if="rform.formData.plantillasInforme[ip]['anexos'][ic]['condicionado']"
                                    class="col-20"
                                    label="Característica"
                                    :form="rform"  
                                    :name="'plantillasInforme.'+ip+'.anexos.'+ic+'.condicion.nombre'"
                                    labelProp="nombre"
                                    idProp="nombre"
                                    :dbItems="getCaracteristicas(rform.formData.caracteristicas)"
                                  >
                                  </r-autocomplete>
                                  <r-input
                                    v-if="rform.formData.plantillasInforme[ip]['anexos'][ic]['condicionado']"
                                    class="col-20"
                                    floating-label
                                    :label="'Operación'"
                                    :form="rform"
                                    type="select"
                                    :name="'plantillasInforme.'+ip+'.anexos.'+ic+'.condicion.operacion'"
                                  >
                                    <option value="IGUAL">==</option>
                                    <option value="DIFERENTE">!=</option>
                                    <option value="MAYOR">&gt;</option>
                                    <option value="MENOR">&lt;</option>
                                  </r-input>
                                  <r-input
                                    v-if="rform.formData.plantillasInforme[ip]['anexos'][ic]['condicionado']"
                                    class="col-20"
                                    floating-label
                                    :label="'Valor'"
                                    :form="rform"
                                    :name="'plantillasInforme.'+ip+'.anexos.'+ic+'.condicion.valor'"
                                  ></r-input>
                                  <div
                                    v-if="!rform.formData.plantillasInforme[ip]['anexos'][ic]['condicionado']"
                                    class="col-60"></div>
                                  <f7-link
                                    style="margin-top:5px; position:absolute; right: -20px; top: 16px;"
                                    icon-f7="minus_circle_fill"
                                    color="red"
                                    @click="eliminarAnexoPlantilla(ip, ic)"
                                  ></f7-link>
                                </f7-row>
                              </f7-list-item>
                            </f7-list>
                            <f7-link
                              class="margin-left margin-bottom"
                              v-if="!rform.readonly && rform.formData"
                              @click="nuevoAnexo(plantilla)"
                              style="z-index: 1"
                            >{{$t('formatos.anadirAnexo')}}</f7-link>
                            <div class="last-row-item-in-accordion"></div>
                          </f7-accordion-content>
                        </f7-list-item>
                      </f7-list>
                    </Draggable>
                  </Container>
                  <div v-if="!rform.formData.plantillasInforme || !rform.formData.plantillasInforme.length">{{$t('formatos.mensaje_nueva_plantilla')}}</div>
                  <f7-link v-if="!rform.readonly && rform.formData" @click="rform.formData.plantillasInforme.push({provincias: [], condiciones: [], anexos: []})">{{$t('formatos.nueva_plantilla')}}</f7-link>
                </f7-block-title>
              </f7-card>
            </f7-tab>
          </f7-tabs>
        </div>

        <!-- Panel derecho -->
        <div v-if="tabActive == 'INSTALACION' || (tabActive == 'TOMADATOS' && subTabTomaDatosActive == 'COMPROBACION')" style="width: 250px;" class="bg-color-white full-height">
          <f7-block-title v-if="tabActive == 'INSTALACION'" class="title-right">Características</f7-block-title>
          <f7-block v-if="tabActive == 'INSTALACION'">
            <Container
              group-name="grupo-caracteristicas"
              behaviour="copy"
              :get-child-payload="getChildPayloadGrupoCaracteristicas(null)"
            >
              <Draggable>
                <f7-button :href="false" large raised class="bg-color-white margin-vertical-half">
                  <f7-icon class="icon-placeholder" f7="folder" />Grupo
                </f7-button>
              </Draggable>
            </Container>
            <Container
              group-name="caracteristicas"
              behaviour="copy"
              :get-child-payload="getChildPayloadCaracteristicas(null)"
            >
              <Draggable v-for="btn in camposCaracteristicas" :key="'btn-caracteristica-'+btn.tipo">
                <f7-button large :href="false" raised class="bg-color-white margin-vertical-half">
                  <f7-icon class="icon-placeholder" :f7="btn.icono" />
                  {{btn.nombre}}
                </f7-button>
              </Draggable>
            </Container>
          </f7-block>
          <f7-block-title v-if="tabActive == 'TOMADATOS' && subTabTomaDatosActive == 'COMPROBACION'" class="title-right">Comprobaciones</f7-block-title>
          <f7-block v-if="tabActive == 'TOMADATOS' && subTabTomaDatosActive == 'COMPROBACION'">
            <Container
              group-name="grupo-comprobaciones"
              behaviour="copy"
              :get-child-payload="getChildPayloadGrupoComprobaciones(null)"
            >
              <Draggable>
                <f7-button :href="false" large raised class="bg-color-white margin-vertical-half">
                  <f7-icon class="icon-placeholder" f7="folder" />Grupo
                </f7-button>
              </Draggable>
            </Container>
            <Container
              group-name="comprobaciones"
              behaviour="copy"
              :get-child-payload="getChildPayloadComprobaciones(null)"
            >
              <Draggable>
                <f7-button :href="false" large raised class="bg-color-white margin-vertical-half">
                  <f7-icon class="icon-placeholder" f7="checkmark_rectangle" />Comprobación
                </f7-button>
              </Draggable>
            </Container>
          </f7-block>
          <!-- <f7-block-title class="title-right">Pestañas</f7-block-title> -->
          <!-- <f7-block>
            <Container
              group-name="pestanas"
              behaviour="copy"
              :get-child-payload="getChildPayloadPestanas(null)"
            >
              <Draggable>
                <f7-button :href="false" large raised class="bg-color-white margin-vertical-half">
                  <f7-icon class="icon-placeholder" f7="checkmark_rectangle" />Genérico
                </f7-button>
              </Draggable>
              <Draggable>
                <f7-button :href="false" large raised class="bg-color-white margin-vertical-half">
                  <f7-icon class="icon-placeholder" f7="square_split_2x2" />Cuadros
                </f7-button>
              </Draggable>
              <Draggable>
                <f7-button :href="false" large raised class="bg-color-white margin-vertical-half">
                  <f7-icon class="icon-placeholder" f7="bolt" />Transformadores
                </f7-button>
              </Draggable>
            </Container>
          </f7-block> -->
        </div>
      </template>
    </r-form>
    <f7-popup class="popuppaste" ref="popuppaste" swipeToClose>
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>{{$t('cerrar')}}</f7-link>
          </f7-nav-left>
          <f7-nav-title>
            {{$t('common.paste')}}
          </f7-nav-title>
          <f7-nav-right>
            
          </f7-nav-right>
        </f7-navbar>
        <f7-list no-hairlines-md>
          <f7-row class="list list-form no-hairlines">
            <f7-col width="100" medium="100">
              <textarea
                ref="textareaPaste"
                type="textarea"
                placeholder="Pegue aqui el contenido del portapapeles"
                @paste.prevent="paste"
              ></textarea>
            </f7-col>
          </f7-row>
        </f7-list>
      </f7-page>
    </f7-popup>
    <f7-popup class="popupimportar" ref="popupimportar" @popup:close="$refs.rformImport.clear()" swipeToClose>
      <r-form
        ref="rformImport"
        :readonly="false"
        :dbAdapter="Formato"
        :documentoAdapter="Documento"
        :beforeSave="beforeSaveImport"
        @save="onImport"
        @error="onImportError"
      >
        <template v-slot="{rform}">
          <f7-page>
            <f7-navbar>
              <f7-nav-left>
                <f7-link popup-close>{{$t('cerrar')}}</f7-link>
              </f7-nav-left>
              <f7-nav-title>
                {{$t('formatos.importacionComprobaciones')}}
              </f7-nav-title>
              <f7-nav-right>
                
              </f7-nav-right>
            </f7-navbar>
            <f7-list no-hairlines-md>
              <f7-row class="list list-form no-hairlines">
                <f7-col width="100" medium="100">
                  <r-files :form="rform" name="documento" :maxFiles='1' label="Importar Documento" @loadFile="loadFile" :preview="false"></r-files>
                </f7-col>
              </f7-row>
            </f7-list>
          </f7-page>
        </template>
      </r-form>
    </f7-popup>
    <PopupProbarAuditoria v-if="probarAuditoria" @close="probarAuditoria = false" :formatoOrigen="$refs.rform.formData"></PopupProbarAuditoria>
  </f7-page>
  
</template>
<style scoped>
.title-right {
  min-height: 17px;
}
.container-grupo {
  border: solid 2px black;
}
.menu-item-dropdown .menu-item-content:after {
  content: inherit !important;
}
.tab-link-active {
  background-color: var(--f7-theme-color-shade) !important;
}
.icon-placeholder {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.2;
  font-size: 2.8em;
  transform: rotate(-15deg);
}
[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  opacity: 0.5;
  color: red;
}
.tab-link-active .item-media,
.accordion-item-opened .accordion-item-toggle .item-media {
  color: var(--f7-theme-color) !important;
}
.accordion-item {
  background-color: #f3f3f3;
}
.accordion-item-content {
  background-color: white;
}
.block-title > input {
  background: transparent;
  border: none;
  font-weight: bold;
  width: 100%;
}
.accordion-item > .item-link input {
  border: none;
  background: transparent;
  width: auto;
  width: 100%;
}
.accordion-item > .nombre-precarga,
.accordion-item > .nombre-plantilla {
  border: none;
  background: transparent;
  width: auto;
  width: 95%;
}
.accordion-item .file-container{
  display:inline-block;
  width: 150px;
}
.last-row-item-in-accordion {
  margin-bottom: 10em;
}
.bgcolor-item {
  background-color: #e9e7f3;
}

.separador-horizontal {
  width: 100%;
  height: 23px;
}

.cabecera-condiciones {
  font-weight: bold;
  display: flex;
  padding: 0 12px;
}

/* estilo para el textarea de nuevas reglas */
div.textarea-reglas {
  height: calc(var(--f7-popup-tablet-height) - calc(calc(var(--f7-navbar-height) + var(--f7-safe-area-top))));
  overflow: hidden;
}
.textarea-reglas * {
  height: 100%;
  margin: 0;
}
.row-100 .item-content {
  width: 100%;
}
li.row-100 div.item-content, li.row-100 div.item-content * {
  width: 100% !important;
  background-color: yellow;
}

.pie-popup {
  font-size: small;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.row-auto {
  height: auto;
}

ul > li.row-valores {
  justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    --f7-cols-per-row: 1;
}

ul > li.row-valores div.item-content {
  width: 100%;
}

/* Las reglas bloqueantes las pondremos en negrita */
.regla-bloqueante {
  /*font-weight: bold;*/
}
.linea-regla-bloqueante {
  /* de color rojo */
  /*border: 1px solid #4b0000;*/
  margin: 1px;
}
/* Las reglas informativas las pondremos en cursiva */
.regla-informativa {
  font-style: italic;
}

.linea-regla-informativa {
  /* de color verde */
  /*border: 1px solid #000052;*/
  margin: 1px;
}
.small-links-container {
  position: relative;
  width: 22px;
  height: 32px;
}

.link-arriba {
  position: absolute;
  top: 1px;
  left: -2px;
  z-index: 10;
}

.link-abajo {
  position: absolute;
  bottom: 1px;
  left: -2px;
  z-index: 10;
}

div.small-links-container a.link-arriba i.f7-icons, 
div.small-links-container a.link-abajo i.f7-icons {
  font-size: 12px !important;
}

</style>
<style>

/* 
   clase para poner un pequeño botón en la esquina superior derecha de la tabla
*/
.ajustar-ancho::after {
  position:absolute;
  right: 0;
  content: '\f152';
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.regla-bloqueante input[type="text"] {
  color: #4b0000;
}
.regla-informativa input[type="text"] {
  color: #000052;
}

.item-anexo .item-inner {
  display: block;
  margin-right: 30px;
}

div.col > div.numero-regla {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  padding-top: var(--f7-list-item-padding-vertical);
  padding-bottom: var(--f7-list-item-padding-vertical);
  min-height: var(--f7-list-item-min-height);
}

.expandir-tabla {
    border: 1px solid #043975;
    color: white;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    background-color: #043975;
  }
</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rRichTextInput from "./../../components/rRichTextInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rAutocompleteM2m from "./../../components/rAutocompleteM2m.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import Formato from "./../../js/db/Formato.js";
import Provincia from "./../../js/db/Provincia.js";
import Materia from "./../../js/db/Materia.js";
import Documento from "./../../js/db/Documento.js";
import DocumentoGestion from "./../../js/db/DocumentoGestion.js";
import rPanel from "./../../components/rPanel.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import Caracteristica from "./../inspecciones/Caracteristica.vue";
import Comprobacion from "./../inspecciones/Comprobacion.vue";
import Property from "./../../js/db/Property.js";
import Utils from "./../../js/Utils.js";
import PopupProbarAuditoria from "./popup-probar-auditoria.vue"
import moment from 'moment'
import DetalleRegla from './detalle-regla.vue';

const applyDrag = (arr, dragResult, vueSelf) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;
  console.log(arr, dragResult);
  //const result = [...arr];
  if (!arr) arr = [];
  var result = arr;

  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = Object.assign({},  result.splice(removedIndex, 1)[0]);
  }

  if (addedIndex !== null) {
    result.push(Object.assign({}, itemToAdd));
    if (vueSelf) {
      vueSelf.$nextTick(_ => {
        result.splice(addedIndex, 0, result.pop());
      })
    } else {
      result.splice(addedIndex, 0, result.pop());
    }
  }

  //return result;
};

export default {
  components: {
    rForm,
    rInput,
    rRichTextInput,
    rTimepicker,
    rFiles,
    rAutocomplete,
    rAutocompleteM2m,
    rPanel,
    Container,
    Draggable,
    Caracteristica,
    Comprobacion,
    PopupProbarAuditoria,
    DetalleRegla
  },
  data: function() {
    var self = this;
    return {
      Formato,
      Provincia,
      Materia,
      Documento,
      DocumentoGestion,
      Property,
      Utils,
      readonly: !this.editable,
      rPanelOpenedByBreakpoint: false,
      precargaHabilitada: false,
      camposCaracteristicas: [
        { tipo: "TEXT", nombre: "Texto", icono: "textbox" },
        { tipo: "TEXTAREA", nombre: "Texto largo", icono: "text_justifyleft" },
        { tipo: "NOTA", nombre: "Nota", icono: "plus_bubble_fill" },
        {
          tipo: "NUMBER",
          nombre: "Número",
          icono: "number"
        },
        { tipo: "CHECK", nombre: "Check SI/NO", icono: "checkmark_rectangle" },
        {
          tipo: "MULTILIST",
          nombre: "Lista de checks",
          icono: "text_badge_checkmark"
        },
        {
          tipo: "SIMPLELIST",
          nombre: "Lista de opciones",
          icono: "list_number"
        },
        { tipo: "DATE", nombre: "Fecha", icono: "calendar" },
        { tipo: "TABLA", nombre: "Tabla", icono: "table" },
        { tipo: "TABLA_PERSONALIZADA", nombre: "Tabla personalizada", icono: "table_badge_more" },
        { tipo: "FIRMA", nombre: "Firma", icono: "signature" },
        { tipo: "TRANSFORMADOR", nombre: "Transformador", icono: "battery_100" },
        { tipo: "AUTOCALCULADO", nombre: "Cálculo", icono: "equal_square" }
      ],
      nuid: 1,
      tipos: {
        certificado: 'CI',
        tomaDatos: 'HD'
      },
      formatoInicial: null,
      formato: null,
      condicionCaracteristica: {},
      documentosPlantilla: [],
      tabActive: "GENERAL",
      subTabTomaDatosActive: "COMPROBACION",
      probarAuditoria: false,
      popupCondicionesAuditoriaOpened: false,
      popupReglaInfoOpened: false,
      popupMultiReglaOpened: false,
      marcadoCondicionesAuditoria: false,
      activadoCondicionesAuditoria: true,
      showCondicionesAuditoria: false,
      showDatosGenerales: true,
      moment: moment
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    },
    version: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    regenerarCondicionesGPT() {
      const self = this;
      const formato = self.$refs.rform.formData;
      const app = self.$f7;
      app.dialog.confirm("¿Está seguro de que desea regenerar las condiciones de auditoría GPT?", () => {
        app.preloader.show();
        Formato.regenerarCondicionesGPT(formato.id)
          .then(_ => {
            app.dialog.alert("Reglas generadas con éxito");
            window.location.reload();
          })
          .catch(function (error) {
            if (error.message) {
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
          })
          .then(_ => app.preloader.hide());
      });
    },
    onDropDefectos(compro, ev) {
      const self = this;
      self.onDrop(compro.defectos, ev); 
      self.$nextTick(() => {
        self.recalculoCodigosDefectos(compro);
      })
    },
    recalculoCodigosDefectos(compro) {
      console.log("RECALCULO", compro.defectos);
      compro.defectos.forEach((def, idx) => {
        def.codigo = compro.codigo + "." + (idx + 1);
      });
    },
    getComprobacionesDefectos(comprobaciones) {
      let compros = [];
      const self = this;
      comprobaciones.forEach(c => {
        if (c.hijas && c.hijas.length) {
          compros = [...compros, ...this.getComprobacionesDefectos(c.hijas)];
        } else {
          if (!c.defectos) {
            self.$set(c, 'defectos', []);
          }
          compros.push(c);
        }
      });
      return compros;
    },
    typeOf(data) {
      return typeof data;
    },
    paste ($event) {
      const self = this;
      const app = self.$f7;
      app.preloader.show();

      try {
        var data = $event.clipboardData.getData('text');
        self.$refs.popuppaste.close();
        self.$refs.textareaPaste.value = "";
        var formato = JSON.parse(data);
        if (formato && formato.pantallacopy && formato.pantallacopy == 'FORMATODETALLE') {
          var formData = self.$refs.rform.formData;
          formato.id = formData.id;
          formato.versionAnterior = formData.versionAnterior;
          formato.versionSiguiente = formData.versionSiguiente;

          self.$set(self.$refs.rform,"formData",formato);
          self.loadItem(formato);
          app.preloader.hide();
          app.dialog.alert('Formato Pegado correctamente');
        } else {
          app.preloader.hide();
          app.dialog.alert('Lo que se quiere copiar no coincide con el destino');
        }
      } catch (e) {
        app.preloader.hide();
        app.dialog.alert('Ha habido algún error al copiar desde el portapapeles');
      }
      return false;
    },
    copy ($event) {
      const self = this;
      const app = self.$f7;
      app.preloader.show();
      const el = document.createElement('textarea');
      var formato = self.$refs.rform.formData
      formato['pantallacopy'] = 'FORMATODETALLE';
      el.value = JSON.stringify(formato);
      delete formato.pantallacopy;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      app.preloader.hide();
      app.toast
        .create({
          text: 'Formato copiado en el portapapeles',
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();

    },
    getComprobacionesSettingGroup (comprobaciones) {
      return comprobaciones.map(c => {
        c.esGrupo = true; 
        return c;
      });
    },
    deleteComprobacion(comprobaciones, ic, comprobacion) {
      var self = this;
      applyDrag(comprobaciones, {removedIndex: ic, payload: null, addedIndex: null});
    },
    exportarComprobaciones(){
      var self = this;
      var formato = self.$refs.rform.formData;
      var app = self.$f7;
      app.preloader.show();
      Formato.exportarComprobaciones(formato).then(_=>app.preloader.hide());
    },
    importarComprobaciones(){
      var self = this;
      var app = self.$f7;
      app.popup.open(".popupimportar");
    },
    onImport: function (res) {
      var self = this;
      var app = self.$f7;
      self.$refs.popupimportar.close();
      app.preloader.hide();
      self.$refs.rform.formData.comprobaciones = res.list;
    },
    onImportError: function (e) {
      var self = this;
      var app = self.$f7;
      self.$refs.popupimportar.close();
      app.preloader.hide(); 
      // TODO mensaje error;
    },
    beforeSaveImport: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var o = Object.assign({}, data);
      o.documento = Array.isArray(o.documento) ? o.documento[0] : o.documento;
      app.preloader.hide();
      resolve(o);
    },
    loadFile: function () {
      var self = this;
      var app = self.$f7;
      self.$refs.rformImport.saveItem();
      app.preloader.show();
    },
    cancelarEdicion(){
      var self = this;
      self.$refs.rform.formData = Object.assign({},self.formatoInicial);
      self.readonly = true;
    },
    beforeSaveFormato: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var formData = data;
      
      formData.caracteristicas = formData.caracteristicas.map(caracteristica => self._fixOptionsCaracteristica(caracteristica));

      formData.plantillasInforme.forEach(plantilla => {
        plantilla.plantillas = [];
        if(plantilla.certificadoPath) {
          plantilla.plantillas.push({
            path: plantilla.certificadoPath,
            tipo: self.tipos.certificado
          });
        }
        if(plantilla.datosPath) {
          plantilla.plantillas.push({
            path: plantilla.datosPath,
            tipo: self.tipos.tomaDatos
          });
        }
        if(plantilla.anexos){
          plantilla.anexos.forEach(anexo => {
            anexo.anexo = {
              path: anexo.anexoPath,
              tipo: 'ANEXO'
            }
          })
        }
      });

      resolve(formData);
    },
    getCaracteristicas (caracteristicas) {
      const self = this;
      if (!caracteristicas || !caracteristicas.length) {
        return [];
      }
      var result = Object.assign([], caracteristicas);
      var hijas = caracteristicas.flatMap(c => {
        return self.getCaracteristicas(c.hijas);
      });
      return result.concat(hijas);
    },
    getComprobaciones (comprobaciones) {
      var self = this;
      if (!comprobaciones || !comprobaciones.length) {
        return [];
      }
      var result = Object.assign([], comprobaciones);
      var hijas = comprobaciones.flatMap(c => {
        c.codigoNombre = c.codigo + ' - ' + c.nombre;
        return self.getComprobaciones(c.hijas);
      });
      return result.concat(hijas);
    },
    habilitarPrecargas: function () {
      var self = this;
      var app = self.$f7;
      self.precargaHabilitada = false;
      Property.getList({filter: {nombre: 'formato.precargaHabilitado'} }).then(function(res) {
        self.precargaHabilitada = res && res.list && res.list[0] && res.list[0].valor == "true";
      }).catch(function(error) {
        if (error.message) {
          app.dialog.alert(error.message);
        } else {
          app.dialog.alert(error);
        }
      });
    },
    loadItem(formato) {
      var self = this;
      self.gestionaPrecargas(formato);
      self.gestionaFicherosPlantilla(formato);
      if (!formato.tabs) {
        self.$set(formato,"tabs", []);
      }
      if (!formato.nombreCategoriasDefectos) {
        self.$set(formato,"nombreCategoriasDefectos", {});
      }
      if(formato && formato.condicionesAuditoria && (!formato.listadoCondicionesAuditoria || formato.listadoCondicionesAuditoria.length == 0)) {
        // vamos a trocear condicionesAuditoria por líneas y creamos un listado de objetos {comprobada: false, textoCondicion: linea}
        self.$set(formato, "listadoCondicionesAuditoria", formato.condicionesAuditoria.split("\n").map(linea => ({comprobada: false, textoCondicion: linea})));
      }
      if(formato.listadoCondicionesAuditoria) {
        // ahora le quitamos el número inicial a las reglas y le ponemos numeración automática (número de línea del array + 1)
        formato.listadoCondicionesAuditoria.forEach((regla, idx) => {
          regla.numero = (idx + 1);
          // si la regla ya está generada (tiene campo regla.regla) le actualizamos el campo numeroRegla con el nuevo número
          if(regla.regla) {
            regla.regla.numeroRegla = regla.numero;
          }
          // eliminamos el número inicial
          regla.textoCondicion = regla.textoCondicion.replace(/^\s*\d+\s*\.\s*/, "");
        });
      }
      if(formato.editarEnIntranet == undefined) {
        self.$set(formato, "editarEnIntranet", true);
      }

      if(formato.listadoCondicionesAuditoria && formato.listadoCondicionesAuditoria.length > 0) {
        // si aluna condición de auditoría está marcada como comprobada, marcamos el flag de marcadoCondicionesAuditoria
        self.marcadoCondicionesAuditoria = formato.listadoCondicionesAuditoria.some(regla => regla?.comprobada);
        formato.listadoCondicionesAuditoria.forEach(regla => {
          if (!regla.tipoRegla) {
            regla.tipoRegla = 'bloqueante';
          }
          if(regla.activa == undefined) {
            regla.activa = true;
          }
        });
      }
      
      self.formato = formato;
      self.$set(self.$refs.rform,"formData",formato);
      self.$set(self.$refs.rform.formData,"nuevasReglas",'');
      self.formatoInicial = JSON.parse(JSON.stringify(formato));
    },
    gestionaPrecargas(formato){
      var self = this;
      if (!formato.precargas){
        self.$set(formato,"precargas", []);
        self.$set(formato.precargas, "condiciones", []);
        self.$set(formato.precargas, "valores", []);
      }
    },
    nuevaReglaAuditoria() {
      var self = this;
      var formato = self.$refs.rform.formData;
      if (!formato.listadoCondicionesAuditoria) {
        self.$set(formato, "listadoCondicionesAuditoria", []);
      }
      var app = self.$f7;
      // Abriremos un dialogo para que el usuario introduzca la nueva regla, poniendole un número correlativo en el texto como sugerencia
      
      app.dialog.prompt(
        "Nueva regla de auditoría",
        null,
        function(regla) {
          let numeroRegla = 1;
          if(formato.listadoCondicionesAuditoria.length > 0) {
            // obtenemos el número mayor de las reglas existentes y le sumamos 1
            numeroRegla = Math.max(...formato.listadoCondicionesAuditoria.map(r => parseInt(r.numero))) + 1;
          }
          // revisamos la regla por si le han metido un número por delante
          let reglaSinNumero = regla.replace(/^\s*\d+\s*\.\s*/, "");
          formato.listadoCondicionesAuditoria.push({comprobada: false, activa:true, numero: numeroRegla, textoCondicion: reglaSinNumero, tipoRegla: 'bloqueante'});
          /* document.getElementById(".reglasAuditoria").scrollTop = document.getElementById(".reglasAuditoria").scrollHeight;*/
        }
      );
    },
    nuevaReglaAuditoriaMultiple() {
      var self = this;
      var app = self.$f7;
      var formato = self.$refs.rform.formData;
      try {
        if (!formato.listadoCondicionesAuditoria) {
          self.$set(formato, "listadoCondicionesAuditoria", []);
        }
        // Separamos las reglas por saltos de línea y si empiezan por números, se los eliminamos (\s*\d+\s*\.\s*)
        var reglas = formato.nuevasReglas.split("\n");
        let numeroRegla = 1;
        if(formato.listadoCondicionesAuditoria.length > 0) {
          // obtenemos el número mayor de las reglas existentes y le sumamos 1
          const maxNumero = formato.listadoCondicionesAuditoria.reduce((max, obj) => {
            return parseInt(obj.numero) > max ? parseInt(obj.numero) : max;
          }, -Infinity);
          numeroRegla = maxNumero + 1;
        }
        reglas.forEach(r => {
          r = r.replace(/^\s*\d+\s*[\.\s\-]\s*/, "");
          formato.listadoCondicionesAuditoria.push({comprobada: false, activa:true,numero: (numeroRegla++), textoCondicion: r, tipoRegla: 'bloqueante'});
        });
      } catch (e) {
        app.dialog.alert("Error al añadir las reglas: " + e);
      } finally {
        // Eliminamos el campo nuevasReglas del formato
        self.$set(formato, "nuevasReglas", "");
        try {self.$refs.popupMultiRegla.close();} catch (e) {console.log("Error al cerrar el popup de múltiples reglas: " + e);}
      }
    },
    eliminaReglaAuditoria(index) {
      const self = this;
      const formato = self.$refs.rform.formData;
      formato.listadoCondicionesAuditoria.splice(index, 1);
      // Renumeramos todas las reglas para que tengan el número ordenado
      formato.listadoCondicionesAuditoria.forEach((regla, idx) => {
        regla.numero = idx + 1;
        if(regla.regla) {
          regla.regla.numeroRegla = regla.numero;
        }
      });
    },
    duplicarReglaAuditoria(index) {
      const self = this;
      const formato = self.$refs.rform.formData;
      let original = formato.listadoCondicionesAuditoria[index];
      let copia = JSON.parse(JSON.stringify(original));
      // en la copia vamos a quitar el flag de comprobada y le vamos a poner el número siguiente al máximo de los existentes
      copia.comprobada = false;
      copia.numero = Math.max(...formato.listadoCondicionesAuditoria.map(r => parseInt(r.numero))) + 1;
      // añadimos la copia detrás de la regla original en formato.listadoCondicionesAuditoria
      formato.listadoCondicionesAuditoria.splice(index + 1, 0, copia);
      // Renumeramos todas las reglas para que tengan el número ordenado
      formato.listadoCondicionesAuditoria.forEach((regla, idx) => {
        regla.numero = idx + 1;
        if(regla.regla) {
          regla.regla.numeroRegla = regla.numero;
        }
      });
    },
    eliminaReglaAuditoriaAll() {
      var self = this;
      var app = self.$f7;
      var formato = self.$refs.rform.formData;
      // pedir confirmación de borrado
      app.dialog.confirm("¿Está seguro de que desea eliminar todas las reglas de auditoría?", function() {
        formato.listadoCondicionesAuditoria = [];
      });
    },
    marcarDesmarcarCondicionesAuditoria() {
      var self = this;
      var formato = self.$refs.rform.formData;
      formato.listadoCondicionesAuditoria.forEach(regla => regla.comprobada = self.marcadoCondicionesAuditoria);
      self.marcadoCondicionesAuditoria = !self.marcadoCondicionesAuditoria;
    },
    activarDesactivarTodasCondicionesAuditoria() {
      var self = this;
      var formato = self.$refs.rform.formData;
      formato.listadoCondicionesAuditoria.forEach(regla => regla.activa = self.activadoCondicionesAuditoria);
      self.activadoCondicionesAuditoria = !self.activadoCondicionesAuditoria;
    },
    verReglasAuditoriaComoTexto() {
      const self = this;
      const app = self.$f7;
      let formato = self.$refs.rform.formData;
      let texto = formato.listadoCondicionesAuditoria.map(regla => regla.numero + '. ' + regla.textoCondicion).join("<br/>\n");
      self.$set(self.$refs.rform.formData, "condicionesAuditoriaTexto", texto);
      self.$set(self,"popupCondicionesAuditoriaOpened", true);
    },
    detalleReglaAuditoria(index) {
      const self = this;
      const app = self.$f7;
      let formato = self.$refs.rform.formData;
      let reglaSeleccionada = formato.listadoCondicionesAuditoria[index];
      if (!reglaSeleccionada) {
        app.dialog.alert("No se ha encontrado la regla seleccionada");
        return;
      }
      if(!reglaSeleccionada.regla) {
        reglaSeleccionada.regla = {
          regla: '',
          caracteristicasAfectadas : [ ],
          comprobacionesAfectadas : [ ],
          valoresAfectados : [],
          numeroRegla : index + ''
        };
      }
      self.$set(self.$refs.rform.formData, "reglaSeleccionada", reglaSeleccionada);
      self.$set(self,"popupReglaInfoOpened", true);
    },
    eliminarReglaAuditoria(ip) {
      var self = this;
      var formato = self.$refs.rform.formData;
      formato.listadoCondicionesAuditoria.splice(ip, 1);
    },
    copiarAlPortapapeles(textoACopiar) {
      const self = this;
      const app = self.$f7;
      navigator.clipboard.writeText(textoACopiar)
          .then(() => {
            app.dialog.alert('Texto copiado al portapapeles');
            try {self.$refs.popupCondicionesAuditoriaTexto.close();} catch (e) {console.log("Error al cerrar el popup de texto de condiciones de auditoría: " + e);}
          })
          .catch(err => {
            console.error('Error al copiar el texto: ', err);
          });
    },
    nuevaPrecarga(){
      var self = this;
      var form = self.$refs.rform.formData;
      var nuevaPrecarga = {};
      self.$set(nuevaPrecarga, 'valores', []);
      self.$set(nuevaPrecarga, 'condiciones', []);
      self.$set(nuevaPrecarga, 'provincias', []);;
      form.precargas.push(nuevaPrecarga)
    },
    nuevaCondicionPrecarga(precarga){
      var self = this;
      if(!precarga.condiciones){
        self.$set(precarga, 'condiciones', []);
      }
      precarga.condiciones.push({});
    },
    nuevoValorPrecarga(ip){
      var self = this;
      var form = self.$refs.rform.formData;
      var nuevoValor = {};
      self.$set(nuevoValor, "resultado", null);
      self.$set(nuevoValor, "bloqueado", false);
      self.$set(nuevoValor, "codigo", null);
      form.precargas[ip].valores.push(nuevoValor);
    },
    setValorComprobacion(ip,iv,valor){
      var self = this;
      var formato = self.$refs.rform.formData;
      self.$set(formato.precargas[ip].valores[iv], 'resultado', valor);
    },
    getValorComprobacion(ip,iv){
      var self = this;
      var formato = self.$refs.rform.formData;
      return formato.precargas[ip].valores[iv].resultado;
    },
    toggleBloqueado(ip,iv){
      var self = this;
      var formato = self.$refs.rform.formData;
      self.$set(formato.precargas[ip].valores[iv], 'bloqueado', !formato.precargas[ip].valores[iv].bloqueado);
    },
    getBloqueado(ip,iv){
      var self = this;
      var formato = self.$refs.rform.formData;
      return formato.precargas[ip].valores[iv].bloqueado;
    },
    gestionaFicherosPlantilla(formato){
      var self = this;
      if (!formato.plantillasInforme){
        self.$set(formato,"plantillasInforme", []);
        self.$set(formato.plantillasInforme, "condiciones", []);
      }
      formato.plantillasInforme.forEach(plantilla => {
        self.$set(plantilla, 'certificado', plantilla.plantillas.filter(documento => documento.tipo == self.tipos.certificado));
      });
      formato.plantillasInforme.forEach(plantilla => {
        self.$set(plantilla, 'datos', plantilla.plantillas.filter(documento => documento.tipo == self.tipos.tomaDatos));
      });
      formato.plantillasInforme.forEach(plantilla => {
        if(plantilla.certificado && plantilla.certificado.length){
          plantilla.certificadoPath = plantilla.certificado[0].path;
        }
        if(plantilla.datos && plantilla.datos.length){
          plantilla.datosPath = plantilla.datos[0].path;
        }
        if(plantilla.anexos && plantilla.anexos.length){
          plantilla.anexos.forEach(anexo => {
            anexo.anexoPath = anexo.anexo.path;
          })
        }
      });
    },
    onDrop(arr, dropResult) {
      var self = this;
      applyDrag(arr, dropResult, self);
    },
    getChildPayloadPestanas(arr) {
      if (!arr) {
        return index => ({
          nombre: index == 0 ? "" : index == 1 ? "CUADROS" : "TRANSFORMADORES",
          formato: null,
          items: null,
          tipo:
            index == 0 ? "GENERAL" : index == 1 ? "CUADROS" : "TRANSFORMADORES"
        });
      } else return index => arr[index];
    },
    getChildPayloadGrupoComprobaciones(arr) {
      if (!arr) {
        return index => ({
          codigo: null,
          nombre: null,
          esGrupo: true,
          descripcion: null,
          instrucciones: null,
          valorInicial: null,
          resultado: null,
          hijas: [],
          defectos: []
        });
      } else return index => arr[index];
    },
    getChildPayloadGrupoCaracteristicas(arr) {
      if (!arr) {
        return index => ({
          nombre: null,
          valor: null,
          descripcion: null,
          label: null,
          tipo: "GROUP",
          maxLength: null,
          help: null,
          hint: null,
          options: null,
          hijas: []
        });
      } else return index => arr[index];
    },
    getChildPayloadComprobaciones(arr) {
      var self = this;
      if (!arr) {
        return index => ({
          codigo: null,
          nombre: null,
          descripcion: null,
          instrucciones: null,
          valorInicial: null,
          resultado: null,
          hijas: [],
          defectos: [],
          name: '',
          creadaLocal: true
        });
      } else return index => arr[index];
    },
    getChildPayloadCaracteristicas(arr) {
      var self = this;
      if (!arr) {
        return index => ({
          nombre: "nuevo",
          valor: null,
          descripcion: null,
          label: null,
          tipo: self.camposCaracteristicas[index].tipo,
          maxLength: null,
          help: null,
          hint: null,
          options: [],
          hijas: []
        });
      } else return index => arr[index];
    },
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Materia.delete({ itemId: self.id })
            .then(function(res) {
              if (res) {
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("rlist.registro_eliminado"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
                self.$f7router.back();
              }
            })
            .catch(function(error) {
              app.dialog.alert(error.message);
            });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.gestionaFicherosPlantilla(res);
      app.toast
        .create({
          text: self.$t("formatos.formato_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    eliminarPlantilla: function(ip){
      var self = this;
      self.$refs.rform.formData.plantillasInforme.splice(ip,1);
    },
    duplicarPlantilla: function(plantilla, idx){
      var self = this;
      let nuevaPlantilla = JSON.parse(JSON.stringify(plantilla));
      nuevaPlantilla.nombre = nuevaPlantilla.nombre + " - Copia";
      self.$refs.rform.formData.plantillasInforme.splice(idx+1,0,nuevaPlantilla);
    },
    eliminarCondicionPlantilla: function(ip, ic){
      var self = this;
      self.$refs.rform.formData.plantillasInforme[ip].condiciones.splice(ic,1);
    },
    eliminarPrecarga: function(ip){
      var self = this;
      self.$refs.rform.formData.precargas.splice(ip,1);
    },
    duplicarPrecarga: function(ip){
      var self = this;
      var precarga = self.$refs.rform.formData.precargas[ip];
      var nuevaPrecarga = JSON.parse(JSON.stringify(precarga));
      nuevaPrecarga.nombre = nuevaPrecarga.nombre + " - DUPLICADA";
      self.$refs.rform.formData.precargas.push(nuevaPrecarga);
    },
    eliminarCondicionPrecarga: function(ip, ic){
      var self = this;
      self.$refs.rform.formData.precargas[ip].condiciones.splice(ic,1);
    },
    _fixOptionsCaracteristica: function(caracteristica) {
      var self = this;
      var nuevaCaracteristica = Object.assign({}, caracteristica);
      if(nuevaCaracteristica.options){
          nuevaCaracteristica.options = nuevaCaracteristica.options.map(option => (option == null) ? '' : option);
      }
      if(nuevaCaracteristica.hijas && nuevaCaracteristica.hijas.length){
          nuevaCaracteristica.hijas = nuevaCaracteristica.hijas.map(hija => self._fixOptionsCaracteristica(hija));
      }
      return nuevaCaracteristica;
    },
    nuevoAnexo: function(plantilla) {
      const self = this;
      if(!plantilla.anexos){
        self.$set(plantilla, "anexos", []);
      }
      plantilla.anexos.push({});
    },
    eliminarAnexoPlantilla: function(ip, ic){
      var self = this;
      self.$refs.rform.formData.plantillasInforme[ip].anexos.splice(ic,1);
    },
  },
  created: function() {
    var self = this;
    DocumentoGestion.getListaDocumentosPorTipo("EXPEDIENTE").then(res => {
      self.documentosPlantilla = res.list;
      if(self.$refs.documentosPlantilla) {
        if(self.$refs.documentosPlantilla.length){
          self.$refs.documentosPlantilla.forEach(comboPlantilla => { comboPlantilla.reload(self.documentosPlantilla); });
        } else {
          self.$refs.documentosPlantilla.reload(self.documentosPlantilla);
        }
      }
    });
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
    if(self.version) {
      app.preloader.show();
      Formato.getVersion({itemId:self.id}).then(function(res) {
        self.$set(self.$refs.rform,"formData",res);
        self.loadItem(res);
      }).catch(function(error) {
        app.dialog.alert(error.message);
        self.$f7router.back();
      }).finally(function(res) {
        app.preloader.hide();
      });
    }
    self.habilitarPrecargas();
  }
};
</script>