<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('pedidos.lineaspedidos')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <r-dynamic-list
        v-if="selectedReparto"
        ref="rlist"
        :dbAdapter="LineaPedido"
        :tableCode="selectedReparto"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['numero','codigo','lineas.estado.estado', 'lineas.numero', 'lineas.tipoLinea', 'lineas.direccionInspeccion.cp', 'lineas.direccionInspeccion.poblacion', 'lineas.direccionInspeccion.provincia', 'lineas.direccionInspeccion.comunidad', 'lineas.idExpediente', 'contratacion.codEmpleado', 'cliente.empleadoVentas', 'cliente.responsable', 'cliente.nif', 'lineas.inspectores', 'lineas.camposUsuario.cpEj', 'lineas.calificacion', 'lineas.expedienteManual', 'lineas.estadoExpediente', 'lineas.emailComunicaciones', 'emailContacto', 'lineas.administrador', 'lineas.instalador', 'lineas.mantenedor', 'lineas.asociacion']"
        :massiveActions="selectedReparto == 'LINEAPEDIDO'"
        :funcShowCheck="showCheckInList"
        :primary="['id','lineas.numero','reparto.code']"
        :controlsWidth="90"
        :searchOnInit="searchOnInit"
        :beforeSearch="beforeSearch"
        :paginationFilterSort="true"
      >
        <template v-slot:tableLinks>
          <f7-list no-hairlines-md>
            <f7-list-input
              :value="selectedReparto"
              @change="changeReparto"
              :type="'select'"
              outline
              floating-label
              label="Reparto"
            >
              <option value="LINEAPEDIDO" selected>Sin Reparto</option>
              <option value="REPARTOCONTRATACION">Reparto de Contratación</option>
              <option value="REPARTOEJECUCION">Reparto de Ejecución</option>
            </f7-list-input>
          </f7-list>
        </template>
        <template v-slot:optionsColumn="{item}">
          <f7-link v-tooltip="$t('common.ver') + ' Pedido'" icon-f7="eye" color="green" :href="`/pedidos/view/${item.id}/`"></f7-link>
          <f7-link
            external
            v-tooltip="'Ver Expediente'"
            v-if="item.lineas.idExpediente"
            :icon-f7="item.lineas.expedienteManual ? 'doc_plaintext' : 'checkmark_seal'"
            :color="getColorExpediente(item.lineas)"
            :href="`#!/inspecciones/view/${item.lineas.idExpediente}/`"
          ></f7-link>
          <f7-link
            v-tooltip="'Crear Exp.Manual'"
            v-if="!item.lineas.idExpediente || (item.lineas.estadoExpediente && item.lineas.estadoExpediente == 'PLANIFICADO')"
            :icon-f7="'hand_raised'"
            @click="crearExpManualConLinea(item.id, item.lineas.numero)"
          ></f7-link>
        </template>
        <template v-slot:massiveActions="{selecteds}">
          <f7-button
            fill
            icon-f7="doc_chart_fill"
            text="Planificar"
            class="display-flex"
            color="blue"
            @click="planificar(selecteds)"
          ></f7-button>
          <f7-button
            fill
            icon-f7="doc_chart_fill"
            text="Planificar TPI"
            class="display-flex"
            color="blue"
            @click="planificar(selecteds, true)"
          ></f7-button>
          <f7-button
            fill
            icon-f7="envelope"
            text="Enviar email"
            class="display-flex"
            color="purple"
            @click="enviarEmail = true"
          ></f7-button>
        </template>
      </r-dynamic-list>
    </f7-page-content>
    <envio-mail
      v-if="enviarEmail"
      ref="enviarEmail"
      :selected="filasSeleccionadas()"
      @email-enviado="enviarEmail = false"
    ></envio-mail>
    
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import EnvioMail from "../pedidos/components/envio-mail.vue";
import LineaPedido from "./../../js/db/LineaPedido.js";

export default {
  components: {
    rDynamicList,
    EnvioMail
  },
  data() {
    return {
      LineaPedido,
      selectedReparto: false,
      enviarEmail: false
    };
  },
  props: {
    searchOnInit: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {
    getColorExpediente: function (linea) {

      if(linea.calificacion == 'FAVORABLE' || linea.calificacion =='FAVORABLE_CON_DEFECTOS' || linea.calificacion =='FAVORABLE_CON_REPARO' ) {
        return "green";
      }
      if(linea.calificacion == 'DESFAVORABLE') {
        return "orange";
      }
      if(linea.calificacion == 'NEGATIVO') {
        return "red";
      }

    },
    beforeSearch: function(filter) {
      const self = this;
      if (self.selectedReparto && self.selectedReparto != 'LINEAPEDIDO') {
        filter.tipoReparto = self.selectedReparto;
      } 
      return filter;
    },
    changeReparto: function(event) {
      const self = this;
      const app = self.$f7;
      app.preloader.show();
      self.selectedReparto = event.currentTarget.value;
      self.$refs.rlist.changeTable(self.selectedReparto, self.selectedReparto == 'LINEAPEDIDO');
      app.preloader.hide();
    },
    filasSeleccionadas: function() {
      let lineas = [...Object.values(this.$refs.rlist.selected)].map(selected => {
        return {id: selected.id, numerosLinea: [selected.lineas.numero]};
      });
      
      return lineas;
    },
    showCheckInList: function(item) {
      const self = this;
      // return item.lineas.estado && item.lineas.estado.estado && item.lineas.estado.estado == "CREADO_PEDIDO"; 
      return self.selectedReparto == 'LINEAPEDIDO';
    },
    crearExpManualConLinea: function(idPedido, numLinea) {
      var self = this;
      var app = self.$f7;
      var jsonData = {idPedido: idPedido, numLinea: numLinea};
      jsonData = Object.values(jsonData);
        app.views.current.router.navigate(
          "/inspecciones/new/?jsonData=" + JSON.stringify(jsonData)
        );
    },
    planificar: function(selecteds, esTPI = false) {
      var self = this;
      var app = self.$f7;
      var msgError = undefined;
      var jsonData = [];
      Object.values(selecteds).forEach(selected => {
        if (!selected.lineas.tipoLinea || selected.lineas.tipoLinea == "TASAS" || selected.lineas.tipoLinea == "SUBCONTRATACION") {
          msgError = "La línea " + selected.lineas.numero + " del pedido " + selected.codigo + " es de tipo " + selected.lineas.tipoLinea + " y no se puede planificar.";
          app.dialog.alert(msgError);
          return;
        } else if (!selected.lineas.direccionInspeccion) {
          msgError = "La línea " + selected.lineas.numero + " del pedido " + selected.codigo + " no tiene dirección asociada.";
          app.dialog.alert(msgError);
          return;
        } else {
          if (!jsonData[selected.id]) {
            jsonData[selected.id] = {idPedido: selected.id, numeroLineas: []};
          }
          jsonData[selected.id].numeroLineas.push(selected.lineas.numero);
        }
      });
      if (!msgError) {
        jsonData = Object.values(jsonData);
        if (esTPI) {
            app.views.current.router.navigate(
              "/pedidos/planificar-tpi/?jsonData=" + JSON.stringify(jsonData)
            );
        } else {
            app.views.current.router.navigate(
              "/pedidos/planificar/?jsonData=" + JSON.stringify(jsonData)
            );
        }
      }
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    // miramos si  en la url viene el parámetro selectedReparto
    let parametros = self.$f7route.url.split('?')[1];
    let filter;
    if (parametros) {
      try {
        eval(parametros.replaceAll('&',';'));
        
      } catch(_){}
    }

    if (filter && filter.tipoReparto) {
      self.selectedReparto = filter.tipoReparto;
    } else {
      self.selectedReparto = 'LINEAPEDIDO';
    }
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>