<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('pedidos.pedidos')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Pedido"
        :tableCode="'PEDIDO'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['estado.estado', 'contratacion.codEmpleado', 'cliente.empleadoVentas', 'cliente.responsable', 'lineas.inspectores.username', 'lineas.camposUsuario.cpEj', 'lineas.comision', 'lineas.proveedor','lineas.item', 'lineas.administrador', 'lineas.instalador', 'lineas.mantenedor', 'lineas.asociacion','lineas.numero']"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="70"
        :searchOnInit="searchOnInit"
        :primary="['id']"
        :paginationFilterSort="true"
      >
        <template v-slot:optionsColumn="{item}">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/pedidos/view/${item.id}/`"></f7-link>
          <f7-link v-if="!item.estado || (item.estado.estado != 'FACTURADO' && item.estado.estado != 'CANCELADO' && item.estado.estado != 'CERRADO')" v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/pedidos/edit/${item.id}/`"></f7-link>
        </template>
        <template v-slot:massiveActions="{}">
          <f7-button
            fill
            icon-f7="envelope"
            text="Enviar email"
            class="display-flex"
            color="purple"
            @click="enviarEmail = true"
          ></f7-button>
        </template>
      </r-dynamic-list>
    </f7-page-content>
    <envio-mail
      v-if="enviarEmail"
      ref="enviarEmail"
      :selected="filasSeleccionadas()"
      @email-enviado="enviarEmail = false"
    ></envio-mail>

  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import EnvioMail from "../pedidos/components/envio-mail.vue";
import Pedido from "./../../js/db/Pedido.js";

export default {
  components: {
    rDynamicList,
    EnvioMail
  },
  data() {
    return {
      Pedido,
      enviarEmail: false
    };
  },
  props: {
    searchOnInit: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {
    filasSeleccionadas: function() {
      let pedidos = [...Object.values(this.$refs.rlist.selected)]
      // desagregamos  las líneas para tener una sola línea dentro de cada pedido
      let lineasPedido = [];
      pedidos.forEach(p => {
        p.lineas.forEach(l => {
          let lineaDesagregada = {id: p.id};
          lineaDesagregada.numerosLinea = [l.numero];
          lineasPedido.push(lineaDesagregada);
        });
      });
      
      return lineasPedido;
    },
    showCheckInList: function(item) {
      // return item.estado && item.estado.estado && item.estado.estado == "CREADO";
      return item.estado && item.estado.estado && (item.estado.estado !== "CANCELADO" && item.estado.estado !== "CERRADO");
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>