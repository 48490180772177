<template>
  <div class="rlist">
    <f7-card-header>

      <div class="data-table-header-selected display-flex" v-if="Object.keys(selected).length">
        <!-- Selected table title -->
        <div class="data-table-title-selected">
          <span
            class="data-table-selected-count"
          >{{$tc('rlist.n_registros_seleccionados', Object.keys(selected).length, {count:Object.keys(selected).length}) }}</span>
        </div>
        <!-- Selected table actions -->
        <div class="data-table-actions">
          <slot name="massiveActions" :selecteds="selected"></slot>
        </div>
      </div>
    </f7-card-header>  
    <slot name="before" :rlist="self"></slot>
    <div :class="classContent" class>
      <slot name="beforeInner" :rlist="self"></slot>

      <div 
        class="infinite-scroll-content virtual-list-parent" 
        :class="{'full-height': tableMode}"
        :style=" tableMode ? '' : `height:${getListParentHeight()}px`"
      >
        <f7-list virtual-list :virtual-list-params="vlParams" ref="vl" :class="getClasses()">
          <table v-if="tableMode" class="width-100">
            <slot name="header" :rlist="self"></slot>
            <tbody class="ptr-content">
              <div class="ptr-preloader" style="position:absolute;">
                <div class="preloader"></div>
                <div class="ptr-arrow"></div>
              </div>
              <tr colspan="100%" :style="`height:${vlData.topPosition}px`"></tr>
              <slot :vlData="vlData" :rlist="self"></slot>
              <tr
                :style="`height:${vlData.listHeight-vlData.topPosition-(vlData.toIndex-vlData.fromIndex)*vlParams.height}px`"
              >
                <td colspan="100%" style="height:0px;padding:0!important;">
                  <div
                    v-if="infiniteScroll"
                    v-show="loadingInfinite && !loadingPtr"
                    class="preloader infinite-scroll-preloader"
                  ></div>
                </td>
              </tr>
            </tbody>
            <slot name="footer" :rlist="self"></slot>
          </table>

          <div v-else class="width-100">
            <slot name="header" :rlist="self"></slot>
            <div class="ptr-preloader">
              <div class="preloader"></div>
              <div class="ptr-arrow"></div>
            </div>
            <ul class="ptr-content">
              <div :style="`height:${vlData.topPosition}px`"></div>
              <slot :vlData="vlData" :rlist="self"></slot>
              <div
                :style="`height:${vlData.listHeight-vlData.topPosition-(vlData.toIndex-vlData.fromIndex)*vlParams.height}px`"
              >
                <div
                  v-show="loadingInfinite && !loadingPtr"
                  class="preloader infinite-scroll-preloader"
                ></div>
              </div>
            </ul>
          </div>
          <slot v-if="!loadingPtr&&!loadingInfinite&&!vlData.items.length" name="empty"></slot>
          <div v-if="!infiniteScroll && tableMode" class="pagina"> <f7-link :class="{'pag1': page == 1}" @click="paginaAnterior()"><f7-icon f7="chevron_left"></f7-icon></f7-link> Página {{page}} <f7-link @click="paginaSiguiente()"><f7-icon f7="chevron_right"></f7-icon></f7-link> </div>
        </f7-list>
      </div>
      <div v-if="!infiniteScroll && !tableMode" class="pagina"> <f7-link :class="{'pag1': page == 1}" @click="paginaAnterior()"><f7-icon f7="chevron_left"></f7-icon></f7-link> Página {{page}} <f7-link @click="paginaSiguiente()"><f7-icon f7="chevron_right"></f7-icon></f7-link> </div>
      <slot name="afterInner" :rlist="self"></slot>
    </div>
    <slot name="after" :rlist="self"></slot>
  </div>
</template>
<script>

import rBadge from './rBadge';

export default {
  components: {
    rBadge
  },
  watch: {
    tableMode: function(value) {
      const self = this;
      const app = self.$f7;
      if (value) {
        self.$nextTick(_ => {
          let $content = self.$$(self.$el).find(".ptr-content");
          app.ptr.done($content);
          app.ptr.create($content[0]);
          if (self.dbAdapter) {
            $content.on("ptr:refresh", function(e) {
              if (self.loadingPtr) return;
              self.initRemoteData();
            });
          }
        });
      } else {
        let $content = self.$$(self.$el).find(".ptr-content");
        app.ptr.done($content);
        $content.on("ptr:refresh", null);
      }
    }
  },
  data() {
    var data = this.items;
    var vlParams = {
      ...{
        //el: ".virtual-list",
        items: data,
        renderExternal: this.renderExternal,
        searchAll: this.searchAll,
        setListHeight: false,
        updatableScroll: false,
        scrollableParentEl: ".virtual-list-parent",
        createUl: false,
        cache: false,
        rowsBefore: 60,
        rowsAfter: 60,
        height: this.$theme.ios ? 44 : this.$theme.md ? 48 : 38
      },
      ...this.virtualListParams
    };

    return {
      self: this,
      selected: {},
      count: 0,
      nomore: false,
      page: 1,
      order: this.sort,
      orderDir: this.sortDir,
      conditions: { ...this.getFilterFromLocalStorage(), ...this.filter },
      //data: data,
      _jqxhr: null,
      loadingInfinite: false,
      loadingPtr: false,
      vlData: {
        items: []
      },
      vlParams: vlParams
    }; // FALTA: virtualListParams como prop y altura automática para parent
  },
  props: {
    classContent: {},
    classList: {},
    dbAdapter: {
      type: Object
    },
    rowsPerPage: {
      type: Number,
      default: 60
    },
    shownFields: {
      type: Array,
      default() {
        return [];
      }
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    virtualListParams: {
      type: Object,
      default() {
        return {};
      }
    },
    primary: {
      type: Array,
      default: function() {
        return ["id"];
      }
    },
    sortInDatabase: {
      type: Boolean,
      default: true
    },
    sort: {
      type: String,
      default: ""
    },
    sortDir: {
      type: String,
      default: "ASC"
    },
    tableMode: {
      type: Boolean,
      default: false
    },
    massiveActions: {
      type: Boolean,
      default: false
    },
    infiniteScroll: {
      type: Boolean,
      default: true
    },
    complete: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object,
      default: function() {
        return {};
      }
    },
    filtersName: String,
    filtersNoCounters: {
      type: Array,
      default: function() {
        return [];
      }
    },
    mostrarBadgeFilters: {
      type: Boolean,
      default: true
    },
    searchOnInit: {
      type: Boolean,
      default: true
    },
    funcShowCheck: {
      type: Function,
      default: function(item) {
        return true;
      }
    }
  },
  mounted() {
    var self = this;
    var app = self.$f7;

    var $content = null;
    if (self.tableMode) {
      $content = self.$$(self.$el).find(".ptr-content");
      app.ptr.create($content[0]);
    }
    var $infinite_content = self.$$(self.$el).find(".infinite-scroll-content");
    app.infiniteScroll.create($infinite_content[0]);

    if (self.dbAdapter) {
      if ($content) {
        $content.on("ptr:refresh", function(e) {
          if (self.loadingPtr) return;
          self.initRemoteData();
        });
      }
      if (self.infiniteScroll) {
        $infinite_content.on("infinite", function() {
          if(!self.infiniteScroll) {
            return;
          }
          if (self.loadingInfinite || self.nomore) return;
          self.page = self.page + 1;
          self.getRemoteData();
        });
      }
      if (self.searchOnInit) {
        self.refresh();
      }
    }
  },
  beforeDestroy() {
    var self = this;
    var app = self.$f7;
    self.$refs.vl.f7VirtualList.deleteAllItems();
  },
  methods: {
    getListParentHeight() {
      return window.outerHeight - 
        (document.getElementsByClassName("card-header").length ? document.getElementsByClassName("card-header")[0].offsetHeight : 0) - 
        (document.getElementsByClassName("pagina").length ? document.getElementsByClassName("pagina")[0].offsetHeight : 0) - 
        (document.getElementsByClassName("appbar").length ? document.getElementsByClassName("appbar")[0].offsetHeight : 0);
    },
    paginaAnterior: function () {
      var self = this;
      if (self.page == 1) {
        return;
      }
      self.page = self.page-1;
      self.getRemoteData();
    },
    paginaSiguiente: function () {
      var self = this;
      self.page = self.page+1;
      self.getRemoteData();
    },
    getFilterFromLocalStorage: function() {
      var self = this;
      var objReturn = {};
      if (self.filtersName) {
        objReturn = localStorage.getItem("filter-" + self.filtersName);
      }
      if (objReturn && typeof objReturn == "string") {
        try {
          objReturn = JSON.parse(objReturn);
        } catch (e) {
          objReturn = {};
        }
      }
      return objReturn || {};
    },
    reload() {
      var self = this;
      if (self.dbAdapter && typeof self.dbAdapter.resetCache == "function") {
        self.dbAdapter.resetCache();
      }
      self.refresh();
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    getValorObjectByNamespaces(item, namespaces, returnArrayIfExists = false) {
      var context = item;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        if (typeof context == "undefined" || context == null) context = {};
        if (!Array.isArray(context) || !returnArrayIfExists) {
          context = context[namespaces[i]];
        }
      }
      return context;
    },
    getPrimary(data) {
      var self = this;
      var prim = "";
      self.primary.forEach(p => {
        var namespaces = p.split(".");
        if (prim) {
          prim = prim + "-";
        }
        // prim = prim + p;
        var val = this.getValorObjectByNamespaces(data, namespaces);
        prim = prim + val;
      })
      return prim;
    },
   toggleSelect: function(data, value) {
      var self = this;
      var dataSelected = self.getPrimary(data);
      if (value) {
        self.$set(self.selected, dataSelected, data);
      } else {
        self.$delete(self.selected, dataSelected);
      }
      self.$emit("select", Object.assign({}, self.selected));
    },
    toggleSelectAll: function(value) {
      var self = this;
      if (value) {
        self.$refs.vl.f7VirtualList.items.forEach(function(data) {
          if (self.funcShowCheck(data)) {
            self.$set(self.selected, data[self.primary], data);
          }
        });
      } else {
        self.selected = {};
      }
    },
    paramsGetRemoteData: function() {
      var self = this;
      return {
        filter: self.conditions,
        limit: self.rowsPerPage == -1 ? undefined : self.rowsPerPage,
        offset:
          self.rowsPerPage == -1 ? undefined : self.rowsPerPage * (self.page - 1),
        page: self.page,
        order: self.order,
        sortInDatabase: self.sortInDatabase,
        orderDir: self.orderDir,
        complete: self.complete,
        projection: self.shownFields && self.shownFields.length ? self.shownFields.join(",") : undefined
      };
    },
    getRemoteData: function() {
      var self = this;
      var app = self.$f7;
      var $content = self.$$(self.$el).find(".ptr-content");
      self.loadingInfinite = true;
      app.preloader.show();
      var params = self.paramsGetRemoteData();
      self.dbAdapter
        .getList(params)
        .then(function(res) {
          if (!res.list.length && (!self.tableMode || parseInt(res.totalCount))) {
            self.page = self.page - 1;
            self.nomore = true;
          } else {
            self.load(res.list);
          }
          self.count = res.totalCount;
          self.$emit("load");
        })
        .catch(function(e) {
          if (typeof e == "string") e = { error: e };
          let error = e.error || e.error_description;
          app.toast
            .create({
              text: error,
              position: "center",
              closeTimeout: 2000
            })
            .open();
          self.page = self.page - 1;
          self.nomore = true;
        })
        .then(function() {
          if (self.tableMode) {
            app.ptr.done($content);
          }
          app.preloader.hide();
          self.loadingInfinite = false;
          self.loadingPtr = false;
        });
    },
    refresh: function() {
      var self = this;
      var app = self.$f7;
      if (self.tableMode) {
        var $content = self.$$(self.$el).find(".ptr-content");
        app.ptr.refresh($content);
      } else {
        self.initRemoteData();
      }
    },
    exportExcel: function(name) {
      var self = this;
      var app = self.$f7;
      var params = self.paramsGetRemoteData();
      params.name = name;
      app.preloader.show();
      self.dbAdapter.exportExcel(params).finally(_ => app.preloader.hide());
    },
    initRemoteData: function() {
      var self = this;
      if (self.loadingPtr) return;
      self.page = 1;
      self.nomore = false;
      self.clearData();
      if (self._jqxhr) self._jqxhr.abort();
      self.loadingPtr = true;
      self.getRemoteData();
    },
    load: function(data) {
      var self = this;
      self.addData(data);
    },
    addData: function(data) {
      var self = this;
      if (!self.infiniteScroll) {
        self.clearData();
      }
      self.$refs.vl.f7VirtualList.appendItems(data);
      self.count = data.length;
    },
    clearData: function() {
      var self = this;
      self.$refs.vl.f7VirtualList.deleteAllItems();
      self.count = 0;
    },
    setOrder: function(order) {
      var self = this;
      var app = self.$f7;
      self.order = order;
      if (self.orderDir == "DESC") {
        self.orderDir = "ASC";
      } else {
        self.orderDir = "DESC";
      }
      self.refresh();
    },
    setFilter: function(filter) {
      var self = this;
      var app = self.$f7;
      self.conditions = filter;
      //self.initRemoteData();
      self.refresh();
    },
    _deleteItem: function(id) {
      var self = this;
      var app = self.$f7;
      return self.dbAdapter
        .delete({ itemId: id })
        .then(function(res) {
          if (res) {
            app.toast
              .create({
                icon: '<i class="f7-icons">checkmark</i>',
                text: self.$t("rlist.registro_eliminado"),
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
            self.removeItem(id);
            self.$emit("deleteItem");
          }
        })
        .catch(function(error) {
          var message = typeof error == "string" ? error : error.message;
          app.dialog.alert(message);
        });
    },
    deleteItem: function(id, silencioso = false) {
      var self = this;
      var app = self.$f7;
      if (silencioso) {
        return self._deleteItem(id);
      } else {
        self.$f7.dialog.confirm(
          self.$t("rlist.pregunta_eliminar_registro"),
          "Eliminar",
          function() {
            self._deleteItem(id);
          }
        );
      }
    },
    removeItem: function(id) {
      var self = this;
      let idx = self.$refs.vl.f7VirtualList.items.findIndex(
        d => d[self.primary] == id
      );
      self.$refs.vl.f7VirtualList.deleteItem(idx);
    },
    sortableClasses: function(field) {
      var rlist = this;
      return {
        "label-cell": true,
        "sortable-cell": true,
        "sortable-cell-active": rlist.order == field,
        "sortable-desc": rlist.orderDir == "DESC",
        "sortable-asc": rlist.orderDir == "ASC"
      };
    },
    getClasses: function() {
      var self = this;
      var classes = Object.assign([], self.classList);
      if (!self.massiveActions) {
        // Si no hay acciones masivas sobre la tabla
        classes.push("hidden-checkboxes");
      }
      return classes;
    }
  }
};
</script>
<style scoped>
.thead-sticky tr:last-child > * {
  top: 0;
}
.tfoot-sticky tr:first-child > * {
  bottom: 0;
}
.tfoot-sticky tr > *,
.thead-sticky tr > * {
  position: -webkit-sticky !important;
  position: sticky !important;
  opacity: 1;
  z-index: 16;
  background: #fff;
}
.left-column-sticky {
  left: 0;
}
.right-column-sticky {
  right: 0;
}
.left-column-sticky,
.right-column-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  opacity: 1;
  z-index: 17 !important;
  background: #f7f7f8;
}

.thead-sticky tr > *.left-column-sticky,
.thead-sticky tr > *.right-column-sticky {
  z-index: 18 !important;
}
</style>
<style>
.pag1 {
  visibility: hidden;
}
.pagina {
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  left: 46%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.ptr-content {
  overflow: visible !important;
}
.full-height {
  display: flex;
  flex-flow: column;
  height: 100% !important;
}

.virtual-list-parent {
  overflow: auto;
}

td,
th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.infinite-scroll-content {
  overflow-x: auto;
}

.hidden-checkboxes .checkbox { 
  visibility: hidden;
}
</style>
